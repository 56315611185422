import React, { ButtonHTMLAttributes, HTMLAttributes, SelectHTMLAttributes } from 'react';
import { BFPanel, BFPopover, IconClose, IconCheckMark } from '@integration-frontends/common/ui';
import classNames from 'classnames';
import './popover-menu.scss';

import { withFormControlStyles } from '@integration-frontends/common/ui/forms/form-controls/common';
import { COMPONENT_RESOLVER_TOKEN, ComponentResolver } from '@integration-frontends/core';
import { useDependencyInjector } from '@integration-frontends/core/di-container/react';
// import { MultiSelect } from '@smartsheet/lodestar-core';
import './select.scss';

export const MULTI_SELECT_COMPONENT_KEY_ = 'MULTI_SELECT';

export enum MultiSelectSize {
  Small = 's',
  Medium = 'm',
}

export type MultiSelectOption = {
  value: string | number;
  label?: string;
};

export type MultiSelectProps = {
  options: MultiSelectOption[];
  selected: MultiSelectOption[];
  value: string[];
  onClear: () => void;
  onChange: any;
  isClearable?: boolean;
  placeholder?: string;
  size?: MultiSelectSize;
  selectedOptions?: MultiSelectOption[];
  getOptionKey?: (opton: MultiSelectOption) => string | number;
  isDisabled?: boolean;
  zIndex?: number;
  panelStyleOptions?: HTMLAttributes<HTMLElement>['style'];
  buttonStyleOptions?: HTMLAttributes<HTMLButtonElement>['style'];
} & Omit<HTMLAttributes<HTMLElement>, 'onChange'> &
  Omit<SelectHTMLAttributes<HTMLElement>, 'onChange' | 'size'>;


export function MultiSelect({
  options,
  selected,
  value,
  onChange,
  onClear,
  zIndex,
  panelStyleOptions,
  buttonStyleOptions,
  ...restProps
}: MultiSelectProps ) {
  return (
    <BFPopover
      button={
        <MultiSelectButton
          selected={selected}
          selectedValues={value}
          onClear={onClear}
          onClick={onChange}
        />
      }
      panel={
        <MultiSelectDropdown
          options={options}
          onClick={onChange}
          selectedValues={value}
          selected={selected}
        />
      }
      zIndex={zIndex || 1}
      panelClass="multi-select"
      buttonStyleOptions={buttonStyleOptions}
      panelStyleOptions={panelStyleOptions}
    />
  )
}

export interface MultiSelectButtonProps {
  selected: MultiSelectOption[];
  onClear: () => void;
}

export function MultiSelectButton ({
  selected,
  selectedValues,
  onClick,
  onClear,
}) {

  const clonedValues = selectedValues.slice();
  return (
    <div className="multi-select-button" tabIndex={0}>
      {selected?.map((option) => (
        <MultiSelectSelectedItem
          onClick={(value) => selectOption(clonedValues, value, onClick)}
          value={`${option.value}`}
          label={option.label}
          key={`selected-${option.value}`}
        />
      ))}
    </div>
  )
}

const selectOption = (valuesArray, value, onClick) => {
  const idx = valuesArray.indexOf(value);
  if (idx >= 0) {
    valuesArray.splice(idx, 1);
  } else {
    valuesArray.push(value);
  }
  onClick(valuesArray);
}

export interface MultiSelectDropdownProps {
  options: MultiSelectOption[];
  selectedValues: string[];
  selected: MultiSelectOption[];
  onClick: (value: any) => void;
}

export function MultiSelectDropdown ({
  options,
  selectedValues,
  onClick,
}: MultiSelectDropdownProps) {
  const clonedValues = selectedValues.slice();

  return (
    <section
      className="multi-select-dropdown"
    >
      {options.map((option) => (
        <MultiSelectItem
          onClick={(value) => selectOption(clonedValues, value, onClick)}
          checked={selectedValues.includes(`${option.value}`)}
          value={`${option.value}`}
          label={option.label}
          key={option.value}
        />
      ))}
    </section>
  )
}

interface MultiSelectItemProps extends ButtonHTMLAttributes<any> {
  checked?: boolean;
  value: string;
  label: string;
  onClick: (value: any) => void;
}

export function MultiSelectItem({
  checked,
  value,
  label,
  onClick,
}: MultiSelectItemProps) {
  return (
    <button
      onClick={() => onClick(value)}
      className={'popover-menu-item multi-select-item hover:bg-bf-marshmallow-light w-max whitespace-nowrap'}
      tabIndex={900}
    >
    {label}
    {(checked && <IconCheckMark />)}
    </button>
  );
}



export function MultiSelectSelectedItem({
  value,
  label,
  onClick,
}: MultiSelectItemProps) {
  return (
    <button
      onClick={(e) => {
        e.preventDefault();
        onClick(value)
      }}
      className={'popover-menu-item multi-select-selected-item w-max whitespace-nowrap'}
    >
    {label}
    <IconClose />
    </button>
  );
}
