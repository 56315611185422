import { CommonUiDropZoneModule } from '@integration-frontends/common/ui';
import { DI_CONTAINER } from '@integration-frontends/core';
import {
  editAttachmentEffects,
  getIntegrationApplicationReducer,
  placeAttachmentEffects,
  searchAssetsEffects,
  selectAttachmentEffects,
  uploadAssetsEffects,
} from '@integration-frontends/integration/core/application';
import { interfaces } from 'inversify';
import { all, fork } from 'redux-saga/effects';
import { initCommonTranslations } from './common/i18n';
import './common/i18n';
import { INTEGRATION_UI_STYLING_OPTIONS_TOKEN } from './styling-options';

// TODO: Create generic module. Improve usability. ~PP
export interface IntegrationUiModuleOptions {
  locale: string;
}

export class IntegrationUiModule {
  static async initialize(
    container: interfaces.Container,
    { locale }: IntegrationUiModuleOptions = { locale: 'en' },
  ) {
    initStylingOptions(container);
    DI_CONTAINER.merge(container);

    await Promise.all([initCommonTranslations(locale), CommonUiDropZoneModule.initialize(locale)]);
  }

  static getRootReducer() {
    return getIntegrationApplicationReducer();
  }

  static getEffects() {
    return function* () {
      yield all([
        fork(editAttachmentEffects),
        fork(placeAttachmentEffects),
        fork(searchAssetsEffects),
        fork(selectAttachmentEffects),
        fork(uploadAssetsEffects),
      ]);
    };
  }
}

function initStylingOptions(container: interfaces.Container) {
  if (!container.isBound(INTEGRATION_UI_STYLING_OPTIONS_TOKEN)) {
    container
      .bind(INTEGRATION_UI_STYLING_OPTIONS_TOKEN)
      .toConstantValue({ backgroundColor: 'white' });
  }
}
