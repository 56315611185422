import classNames from 'classnames';
import React, { HTMLAttributes, InputHTMLAttributes, SelectHTMLAttributes } from 'react';
import './toggle-switch.scss';
import { withFormControlStyles } from './common';
import { ComponentResolver, COMPONENT_RESOLVER_TOKEN } from '@integration-frontends/core';
import { useDependencyInjector } from '@integration-frontends/core/di-container/react';

export const TOGGLE_SWITCH_COMPONENT_KEY = 'TOGGLE_SWITCH';

export type BFToggleSwitchProps = {
  checked: any;
  onChange: any;
} & Omit<HTMLAttributes<HTMLInputElement>, 'onChange'> &
  Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>;

function DefaultComponent(props: BFToggleSwitchProps) {
  return (
    <label className={classNames('switch', props.className)} style={props.style}>
      <input type="checkbox" checked={props.checked} onChange={props.onChange} {...props} />
      <span className="slider round" />
    </label>
  )
}

export const BFToggleSwitch = withFormControlStyles<BFToggleSwitchProps>((props) => {
  const componentResolver = useDependencyInjector<ComponentResolver>(COMPONENT_RESOLVER_TOKEN);
  const Component = componentResolver.getComponent(TOGGLE_SWITCH_COMPONENT_KEY) || DefaultComponent;
  return <Component {...props} />;
});
