import { combineReducers } from '@reduxjs/toolkit';
import { ENTITIES_STATE_REDUCER, IntegrationEntitiesState } from './common/entities-state';
import { PLACE_ATTACHMENT_REDUCER, PlaceAttachmentState } from './place-attachment/state';

import { SEARCH_ASSETS_REDUCER, SearchAssetsState } from './search-assets/state';
import { SELECT_ATTACHMENT_REDUCER, SelectAttachmentState } from './select-attachment/state';
import { UPLOAD_ASSETS_REDUCER, UploadAssetsState } from './upload-assets/state';
import { AppState } from '@integration-frontends/common/app';
import { NotificationsState } from '@integration-frontends/common/notifications';

export interface IntegrationRootState {
  app: AppState;
  notifications: NotificationsState,
  integrationApplication: {
    entities: IntegrationEntitiesState;
    placeAttachment: PlaceAttachmentState;
    selectAttachment: SelectAttachmentState;
    searchAssets: SearchAssetsState;
    uploadAssets: UploadAssetsState;
  };
}

export function getIntegrationApplicationReducer() {
  return {
    integrationApplication: combineReducers({
      entities: ENTITIES_STATE_REDUCER,
      placeAttachment: PLACE_ATTACHMENT_REDUCER,
      selectAttachment: SELECT_ATTACHMENT_REDUCER,
      searchAssets: SEARCH_ASSETS_REDUCER,
      uploadAssets: UPLOAD_ASSETS_REDUCER,
    }),
  };
}
