import { StateSelector } from '@integration-frontends/common/utils/redux';
import { Attachment } from '@integration-frontends/integration/core/model';
import { createSelector, EntityState } from '@reduxjs/toolkit';
import { compose, equals, prop } from 'ramda';
import { attachmentAdapter } from '../entities';

export type AttachmentSelectors = ReturnType<typeof createAttachmentSelectors>;
export function createAttachmentSelectors(stateSelector: StateSelector<EntityState<Attachment>>) {
  const baseSelectors = attachmentAdapter.getSelectors(stateSelector);
  const attachmentsByAssetIdSelector = (assetId: string) =>
    createSelector(baseSelectors.selectAll, (entities) =>
      entities.filter(compose(equals(assetId), prop('assetId'))),
    );
  return {
    ...baseSelectors,
    byAssetId: attachmentsByAssetIdSelector,
  };
}
