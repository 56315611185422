import { StateSelector } from '@integration-frontends/common/utils/redux';
import { assetCustomFieldValueAdapter } from '@integration-frontends/integration/core/application/common/entities-state/entities';
import { AssetCustomFieldValue } from '@integration-frontends/integration/core/model';
import { createSelector, EntityState } from '@reduxjs/toolkit';
import { compose, equals, prop } from 'ramda';

export function createAssetCustomFieldValueSelectors(
  stateSelector: StateSelector<EntityState<AssetCustomFieldValue>>,
) {
  const baseSelectors = assetCustomFieldValueAdapter.getSelectors(stateSelector);

  const assetCustomFieldValuesByAssetIdSelector = (assetId: string) =>
    createSelector(baseSelectors.selectAll, (entities) =>
      entities.filter(compose(equals(assetId), prop('assetId'))),
    );

  return {
    ...baseSelectors,
    byAssetId: assetCustomFieldValuesByAssetIdSelector,
  };
}
