import { StateSelector } from '@integration-frontends/common/utils/redux';
import { AssetTag } from '@integration-frontends/integration/core/model';
import { createSelector, EntityState } from '@reduxjs/toolkit';
import { compose, equals, prop } from 'ramda';
import { assetTagAdapter } from '../entities';

export function createAssetTagSelectors(stateSelector: StateSelector<EntityState<AssetTag>>) {
  const baseSelectors = assetTagAdapter.getSelectors(stateSelector);

  const assetTagsByAssetIdSelector = (assetId: string) => {
    return createSelector(baseSelectors.selectAll, (entities) =>
      entities.filter(compose(equals(assetId), prop('assetId'))),
    );
  };

  return {
    ...baseSelectors,
    byAssetId: assetTagsByAssetIdSelector,
  };
}
