import {
  AttachmentUploadDate,
  SearchParameters,
} from '@integration-frontends/integration/core/model';
import { Disclosure } from '@headlessui/react';
import {
  ExpandableSectionIcon,
  SectionContent,
  SectionHeader,
} from '@integration-frontends/integration/ui/attachment-selector/components/advanced-filters/common';
import {
  ALL_KEY,
  FROM_KEY,
  TO_KEY,
  UPLOAD_DATE_KEY,
  UPLOAD_DATE_LAST_30_MINS_KEY,
  UPLOAD_DATE_PAST_24_HOURS_KEY,
  UPLOAD_DATE_PAST_7_DAYS_KEY,
  UPLOAD_DATE_RANGE_KEY,
} from '@integration-frontends/integration/ui/attachment-selector/i18n';
import { BFDatePicker, BFLabel, BFRadio } from '@integration-frontends/common/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { INTEGRATION_COMMON_NAMESPACE } from '@integration-frontends/integration/ui/common/i18n';

export interface UploadDateFilterComponentProps {
  searchParams: SearchParameters;
  onSelect: (date: AttachmentUploadDate) => void;
  onDateRangeChange: (dateStart: string, dateEnd: string) => void;
  onDateStartChange: (dateStart: string) => void;
  onDateEndChange: (dateEnd: string) => void;
}

export function UploadDateFilterComponent({
  searchParams,
  onSelect,
  onDateEndChange,
  onDateStartChange,
}: UploadDateFilterComponentProps) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);

  function isChecked(attachmentUploadDate: AttachmentUploadDate): boolean {
    return searchParams?.uploadDateFilter?.uploadDateEnum === attachmentUploadDate || false;
  }

  return (
    <Disclosure defaultOpen={!!searchParams?.uploadDateFilter}>
      {({ open }) => (
        <>
          <SectionHeader>
            <Disclosure.Button as="div" className="flex justify-between">
              <span>{t(UPLOAD_DATE_KEY)}</span>
              <ExpandableSectionIcon expanded={open} />
            </Disclosure.Button>
          </SectionHeader>

          <Disclosure.Panel>
            <SectionContent className="pb-lg">
              <div className="flex items-center">
                <BFRadio
                  checked={!searchParams?.uploadDateFilter}
                  onChange={() => onSelect(null)}
                  className="mr-xs"
                  name="upload-date-filter"
                  value="upload-date-filter"
                  label={t(ALL_KEY)}
                />
              </div>
              <div className="flex items-center">
                <BFRadio
                  value={AttachmentUploadDate.Last30Minutes}
                  checked={isChecked(AttachmentUploadDate.Last30Minutes)}
                  onChange={() => onSelect(AttachmentUploadDate.Last30Minutes)}
                  className="mr-xs"
                  name="upload-date-filter"
                  label={t(UPLOAD_DATE_LAST_30_MINS_KEY)}
                />
              </div>
              <div className="flex items-center">
                <BFRadio
                  value={AttachmentUploadDate.Past24Hours}
                  checked={isChecked(AttachmentUploadDate.Past24Hours)}
                  onChange={() => onSelect(AttachmentUploadDate.Past24Hours)}
                  className="mr-xs"
                  name="upload-date-filter"
                  label={t(UPLOAD_DATE_PAST_24_HOURS_KEY)}
                />
              </div>
              <div className="flex items-center">
                <BFRadio
                  value={AttachmentUploadDate.Past7Days}
                  checked={isChecked(AttachmentUploadDate.Past7Days)}
                  onChange={() => onSelect(AttachmentUploadDate.Past7Days)}
                  className="mr-xs"
                  name="upload-date-filter"
                  label={t(UPLOAD_DATE_PAST_7_DAYS_KEY)}
                />
              </div>

              <div className="flex items-center">
                <BFRadio
                  value={AttachmentUploadDate.DateRange}
                  checked={isChecked(AttachmentUploadDate.DateRange)}
                  onChange={() => onSelect(AttachmentUploadDate.DateRange)}
                  className="mr-xs"
                  name="upload-date-filter"
                  label={t(UPLOAD_DATE_RANGE_KEY)}
                />

                {isChecked(AttachmentUploadDate.DateRange) && (
                  <div className="pt-md flex flex-col gap-xs">
                    <div className="flex justify-between items-center">
                      <BFLabel>{t(FROM_KEY)}:</BFLabel>
                      <BFDatePicker
                        value={searchParams?.uploadDateFilter?.dateStart}
                        onChange={(e) => onDateStartChange(e.target.value)}
                      />
                    </div>

                    <div className="flex justify-between items-center">
                      <BFLabel>{t(TO_KEY)}:</BFLabel>
                      <BFDatePicker
                        value={searchParams?.uploadDateFilter?.dateEnd}
                        onChange={(e) => onDateEndChange(e.target.value)}
                      />
                    </div>
                  </div>
                )}
              </div>
            </SectionContent>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
