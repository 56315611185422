import {
  areEqual,
  IMediaTypeSupportService,
  isKnownMediaType,
  MediaType,
} from '@integration-frontends/integration/core/model';
import { injectable } from 'inversify';

abstract class MediaTypeSupportServiceBase implements IMediaTypeSupportService {
  constructor(
    private exportableMediaTypes: MediaType[],
    protected allowedMediaTypes?: MediaType[] | null,
  ) {}

  getAllowedMediaTypes = (): MediaType[] | null => this.allowedMediaTypes || null;

  getExportableMediaTypes = (): MediaType[] => {
    return this.exportableMediaTypes;
  };

  abstract isSupported(mediaType: MediaType): boolean;
}

@injectable()
export class MediaTypeSupportServiceWhitelistStrategy extends MediaTypeSupportServiceBase {
  constructor(
    exportableMediaTypes: MediaType[],
    private supportedMediaTypeWhitelist: MediaType[],
    protected allowedMediaTypes?: MediaType[] | null,
  ) {
    super(exportableMediaTypes, allowedMediaTypes);
  }

  isSupported = (mediaType: MediaType): boolean => {
    return !!this.supportedMediaTypeWhitelist.find(
      (whitelisted) => isKnownMediaType(mediaType) && areEqual(whitelisted, mediaType),
    );
  };
}

@injectable()
export class MediaTypeSupportServiceBlacklistStrategy extends MediaTypeSupportServiceBase {
  constructor(
    exportableMediaTypes: MediaType[],
    private supportedMediaTypesBlacklist: MediaType[],
    protected allowedMediaTypes?: MediaType[] | null,
  ) {
    super(exportableMediaTypes, allowedMediaTypes);
  }

  isSupported = (mediaType: MediaType): boolean => {
    return !this.supportedMediaTypesBlacklist.find((blacklisted) =>
      areEqual(blacklisted, mediaType),
    );
  };
}
