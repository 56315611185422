import { call, put, takeEvery } from 'redux-saga/effects';
import { createNotification } from '../actions';
import {toast} from 'react-toastify';
import { NotificationType } from '../reducer';

const handler = function* (action: ReturnType<typeof createNotification>) {
  const {message, location, type} = action.payload;
  const options = {
    containerId: location,
    hideProgressBar: true,
  };
  switch (type) {
    case NotificationType.Success:
      yield toast.success(message, {className: 'toast-success', ...options});
      break;
    case NotificationType.Error:
      yield toast.error(message, {className: 'toast-error', ...options});
      break;
    default:
      yield toast.success(message, {className: 'toast-success', ...options});
  }
};

export function* newNotificationEffects() {
  yield takeEvery(createNotification, handler);
}
