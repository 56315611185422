import {
  BFAnchor,
  BFButton,
  ButtonSize,
  ButtonType,
  IconCaretDown,
  IconChevronDown,
  IconChevronRight,
  IconSize,
} from '@integration-frontends/common/ui';
import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { COLLECTION_KEY, COLLECTIONS_KEY } from '../i18n';
import { INTEGRATION_COMMON_NAMESPACE } from '@integration-frontends/integration/ui/common/i18n';

export interface CollectionsToggleProps {
  collectionCount: number;
  open: boolean;
  primary?: boolean;
}

export function CollectionsToggle({
  collectionCount,
  open,
  primary,
  ...props
}: CollectionsToggleProps & HTMLAttributes<HTMLDivElement>) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);

  return (
    <div className="flex items-center whitespace-nowrap cursor-pointer" {...props}>
      {primary ? (
        <BFButton
          buttonType={ButtonType.Secondary}
          size={ButtonSize.Small}
          className="flex items-center justify-between"
          iconAfter={
            <IconCaretDown iconSize={IconSize.Small}/>
          }
        >
          {collectionCount} {collectionCount > 1 ? t(COLLECTIONS_KEY) : t(COLLECTION_KEY)}
        </BFButton>
      ) : (
        <>
          {collectionCount} {collectionCount > 1 ? t(COLLECTIONS_KEY) : t(COLLECTION_KEY)}
          {open ? (
            <IconChevronDown className="pl-xxs" iconSize={IconSize.Large} />
          ) : (
            <IconChevronRight className="pl-xxs" iconSize={IconSize.Large} />
          )}
        </>
      )}
    </div>
  );
}
