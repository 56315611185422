import { addToRefs, arrowKeyNavigate, enterToClick } from '@integration-frontends/common/ui';
import { Container } from '@integration-frontends/integration/core/model';
import classNames from 'classnames';
import React, { HTMLAttributes, LegacyRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ASSET_KEY } from '../../i18n';
import { INTEGRATION_COMMON_NAMESPACE } from '../../../common/i18n';
import './containers-compact-list.scss';
import { isNil } from 'lodash';

export interface ContainerCompactListProps {
  containerId?: string;
  containers: Container[];
  containerSelect: (container: Container) => void;
  listItemBackgroundHover?: boolean;
  listItemDelimiter?: boolean;
}

export function ContainersCompactList({
  containerId,
  containers,
  containerSelect,
  className,
  listItemBackgroundHover,
  listItemDelimiter,
  ...props
}: ContainerCompactListProps & HTMLAttributes<any>) {
  const [focusedItem, setFocusedItem] = useState(0);
  const itemRefs = useRef([]);

  useEffect(() => {
    if (itemRefs.current.length) {
      itemRefs.current[focusedItem].focus();
    }
  });

  const handleOnKeyPress = (e) => {
    enterToClick(e);
    arrowKeyNavigate(e, focusedItem, itemRefs.current, setFocusedItem);
  };
  const containerList = containers.map((container, idx) => {
    return (
      <ContainerCompactListItem
        key={container.id}
        className={classNames(
          'container-compact-list-item w-full',
          { delimiter: listItemDelimiter },
          { 'background-hover': listItemBackgroundHover },
          { selected: containerId === container.id },
          className,
        )}
        container={container}
        onClick={() => containerSelect(container)}
        tabIndex={0}
        ref={(ref) => addToRefs(ref, itemRefs.current)}
        onKeyDown={handleOnKeyPress}
        aria-label={`Open collection ${container.name}`}
      />
    );
  });

  return (
    <ul className={classNames('w-full flex flex-col', className)} {...props}>
      {containerList}
    </ul>
  );
}

export interface ContainerCompactListItemProps {
  container: Container;
}

export const ContainerCompactListItem = React.forwardRef(
  (
    { container, className, ...props }: ContainerCompactListItemProps & HTMLAttributes<any>,
    ref: LegacyRef<any>,
  ) => {
    const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
    return (
      <li
        data-cy={container.type}
        className={classNames('overflow-hidden w-full cursor-pointer background-hover', className)}
        {...props}
        ref={ref}
        tabIndex={0}
      >
        <span className="truncate">{container.name}</span>

        {!isNil(container.assetCount) && (
          <span className="asset-count text-sm">
            {container.assetCount.toLocaleString('en-US')}{' '}
            {t(ASSET_KEY, { count: container.assetCount })}
          </span>
        )}
      </li>
    );
  },
);
