import { createAction } from '@reduxjs/toolkit';
import { withPayloadType } from '@integration-frontends/common/utils/redux';

export const init = createAction(
  'INTEGRATION_INIT',
  withPayloadType<{name: string, headerBackgroundUrl?: string}>()
);
export const initSuccess = createAction('INTEGRATION_INIT_SUCCESS');
export const initLoading = createAction('INTEGRATION_INIT_LOADING');
export const initLoaded = createAction('INTEGRATION_INIT_LOADED');
export const pageLoaded = createAction('INTEGRATION_PAGE_LOADED');
export const pageLoadError = createAction('INTEGRATION_PAGE_LOAD_ERROR');
