import { AttachmentThumbnailBase } from './attachment-thumbnail-base';
import { ThumbnailOverlay } from '../../thumbnail-overlay';
import React, { useState } from 'react';
import { enterToClick } from '@integration-frontends/common/ui';
import { VIEW_DETAILS_KEY } from '../../../i18n';
import { useSelector } from 'react-redux';
import { containerSelectors } from '@integration-frontends/integration/core/application';
import { useAttachmentSelectorNavigation } from '../../../navigation';
import { Asset, Attachment } from '@integration-frontends/integration/core/model';
import { useTranslation } from 'react-i18next';
import { INTEGRATION_COMMON_NAMESPACE } from '../../../../common/i18n';
import { AttachmentOptionsMenu } from '../../attachment-options-menu';
import { AttachmentThumbnailProps } from './attachment-thumbnail-props';

export function NavigatableAttachmentThumbnail(props: AttachmentThumbnailProps) {
  return <AttachmentThumbnailBase {...props} overlay={<Overlay {...props} />} />;
}

interface OverlayProps {
  asset: Asset;
  attachment: Attachment;
  containerId: string;
}

function Overlay({ asset, attachment, containerId }: OverlayProps) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const container = useSelector((state) => containerSelectors.selectById(state, containerId));
  const navigation = useAttachmentSelectorNavigation();
  const selectedContainer = useSelector((state) =>
    containerSelectors.selectById(state, containerId),
  );
  const [menuFocus, setMenuFocus] = useState(false);

  const action = (
    <a
      data-testid="attachment-view-details-btn"
      className="attachment-adjust-margin w-max button-neutral"
      onClick={() => navigation.goToAssetDetails(asset.id, selectedContainer.id, attachment.id)}
      tabIndex={0}
      onFocus={() => setMenuFocus(true)}
      onBlur={() => setMenuFocus(false)}
      onKeyDown={enterToClick}
    >
      {t(VIEW_DETAILS_KEY)}
    </a>
  );

  return (
    <ThumbnailOverlay
      className={`asset-overlay ${menuFocus && 'show'}`}
      centerElement={action}
      topElement={
        <AttachmentOptionsMenu
          asset={asset}
          attachment={attachment}
          container={container}
          onFocus={() => setMenuFocus(true)}
          onBlur={() => setMenuFocus(false)}
        />
      }
    />
  );
}
