import { FlexSpacer } from '../../common/layout/flex-spacer';
import { FormControl } from '../../common/layout/form';
import { FILE_TYPE_LABEL_KEY, HEIGHT_KEY, WIDTH_KEY } from '../../i18n';
import { BFInput, BFSelect, BFSelectSize, InputSize } from '@integration-frontends/common/ui';
import {
  Attachment,
  AttachmentVariant,
  getImageMediaTypes,
  getMediaTypesExtensions,
  getVideoMediaTypes,
  isImageType,
  isVideoType,
  KnownMediaType,
} from '@integration-frontends/integration/core/model';
import {
  ResizingOptions,
  setHeight,
  setWidth,
} from '@integration-frontends/integration/core/application';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { INTEGRATION_COMMON_NAMESPACE } from '../../../common/i18n';
import { useMediaTypeSupport } from '../../../common/use-media-type-support';

export interface AttachmentOptionsComponentProps {
  attachment: Attachment | AttachmentVariant;
  selectedMediaType: KnownMediaType;
  onMediaTypeChange: (mediaType: KnownMediaType) => void;
  userResizingOptions: ResizingOptions;
  onResize: (resizingOptions: ResizingOptions) => void;
}

export function AttachmentOptionsComponent({
  attachment,
  onMediaTypeChange,
  onResize,
  selectedMediaType,
  userResizingOptions,
}: AttachmentOptionsComponentProps) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const { exportableMediaTypes } = useMediaTypeSupport();
  const isImage = isImageType(attachment.mediaType);
  const exportMediaTypes: KnownMediaType[] = isImage
    ? getImageMediaTypes(exportableMediaTypes)
    : isVideoType(attachment.mediaType)
    ? getVideoMediaTypes(exportableMediaTypes)
    : [];

  return (
    <FlexSpacer direction={'vertical'}>
      <FlexSpacer>
        <div className="grow">
          <FormControl label={t(FILE_TYPE_LABEL_KEY)}>
            <BFSelect
              size={BFSelectSize.Medium}
              value={selectedMediaType}
              {...(!isImage && { placeholder: selectedMediaType })}
              data-testid="file-type-select"
              onOptionChange={(opt) => opt && onMediaTypeChange(opt.value as KnownMediaType)}
              style={{ width: '100%' }}
              disabled={!isImage}
              options={exportMediaTypes.map((mediaType) => ({
                value: mediaType,
                label: getMediaTypesExtensions([mediaType])?.[0].toLocaleUpperCase(),
              }))}
            />
          </FormControl>
        </div>
        <div className="grow" />
      </FlexSpacer>

      <FlexSpacer>
        <FlexSpacer direction={'vertical'} size={'xs'} className="grow">
          <FormControl label={t(WIDTH_KEY)}>
            <BFInput
              size={InputSize.Medium}
              data-testid="width-input"
              className="w-full"
              type="number"
              disabled={!isImage}
              value={Math.round(userResizingOptions?.newDimensions.width) || ''}
              onChange={(e) => onResize(setWidth(userResizingOptions, parseInt(e.target.value)))}
            />
          </FormControl>
        </FlexSpacer>

        <FlexSpacer direction={'vertical'} size={'xs'} className="grow">
          <FormControl label={t(HEIGHT_KEY)}>
            <BFInput
              size={InputSize.Medium}
              data-testid="height-input"
              className="w-full"
              type="number"
              disabled={!isImage}
              value={Math.round(userResizingOptions?.newDimensions.height) || ''}
              onChange={(e) => onResize(setHeight(userResizingOptions, parseInt(e.target.value)))}
            />
          </FormControl>
        </FlexSpacer>
      </FlexSpacer>
    </FlexSpacer>
  );
}
