import {
  hasFilters,
  SearchParameters,
  Section,
} from '@integration-frontends/integration/core/model';
import { FiltersDisplayContainer } from '../filters-display';
import { ResultsDisplay } from './results-display';
import { AssetsListContainer } from '../assets-list';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ISelectAttachmentOptions,
  searchAssetsSelectors,
  searchRefresh,
  SELECT_ATTACHMENT_OPTIONS_TOKEN,
} from '@integration-frontends/integration/core/application';
import { useTranslation } from 'react-i18next';
import {
  INTEGRATION_COMMON_NAMESPACE,
  SEARCH_ERROR_KEY,
  TRY_AGAIN_KEY,
} from '../../../../common/i18n';
import { DI_CONTAINER } from '@integration-frontends/core';

export interface SearchResultsProps {
  containerId: string;
  searchParams: SearchParameters;
  selectedSection?: Section;
  showAttachments: boolean;
}

export function SearchResults({
  containerId,
  searchParams,
  selectedSection,
  showAttachments,
}: SearchResultsProps) {
  const searchError = useSelector(searchAssetsSelectors.error);
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const dispatch = useDispatch();
  const { hiddenSections = [] }: ISelectAttachmentOptions = DI_CONTAINER.get(
    SELECT_ATTACHMENT_OPTIONS_TOKEN,
  );

  function tryAgain() {
    dispatch(searchRefresh());
  }

  return (
    <div className="flex flex-col gap-xl pb-lg">
      {searchError ? (
        <div data-testid={'search-error'} className="flex flex-col">
          <span className="text-center">{t(SEARCH_ERROR_KEY)}</span>
          <span
            data-testid="try-again-btn"
            className="link text-center"
            role="button"
            onClick={tryAgain}
          >
            {t(TRY_AGAIN_KEY)}
          </span>
        </div>
      ) : (
        <>
          {hasFilters(searchParams) && (
            <>
              <FiltersDisplayContainer searchParams={searchParams} />
              <ResultsDisplay containerId={containerId} />
            </>
          )}
          <AssetsListContainer
            hiddenSections={hiddenSections}
            containerId={containerId}
            selectedSection={selectedSection}
            showAttachments={showAttachments}
          />
        </>
      )}
    </div>
  );
}
