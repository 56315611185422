import { DEFAULT_DATE_FORMAT } from '@integration-frontends/core';
import { Asset, Attachment } from '@integration-frontends/integration/core/model';
import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { INTEGRATION_COMMON_NAMESPACE } from '../../../../common/i18n';
import { PageSection, PageSectionContent } from '../../../../common/layout/page';
import { CREATED_ON_KEY, DETAILS_KEY, EXPIRES_ON_KEY, LAST_UPDATED_ON_KEY } from '../../../i18n';
import './asset-details-component.scss';

export const ASSET_DETAILS_COMPONENT_KEY = 'ASSET_DETAILS';

export interface AssetDetailsComponentProps {
  asset: Asset;
  attachment: Attachment;
}

export function AssetDetailsComponent({ asset }: AssetDetailsComponentProps) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);

  return (
    <PageSection className="asset-details" title={t(DETAILS_KEY)}>
      <PageSectionContent>
        <div>
          {t(LAST_UPDATED_ON_KEY)} {format(asset?.updatedAt, DEFAULT_DATE_FORMAT)}
        </div>
        <div>
          {t(CREATED_ON_KEY)} {format(asset?.createdAt, DEFAULT_DATE_FORMAT)}
        </div>
        <>
          {asset?.availabilityEnd && (
            <div>
              {t(EXPIRES_ON_KEY)} {format(asset?.availabilityEnd, DEFAULT_DATE_FORMAT)}
            </div>
          )}
        </>
      </PageSectionContent>
    </PageSection>
  );
}
