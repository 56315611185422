import { call, takeEvery } from 'redux-saga/effects';
import {
  brandfolderEntityActions,
  collectionEntityActions,
  searchAssetsActions,
  showPageDataLoaded,
  showPageEntered,
} from '@integration-frontends/integration/core/application';
import {
  BRANDFOLDER_REPO_TOKEN,
  COLLECTION_REPO_TOKEN,
  Container,
  CONTAINER_REPO_TOKEN,
  IBrandfolderRepo,
  ICollectionRepo,
  IContainerRepo,
  ResourceType,
  SearchParameters,
  SortOptions,
} from '@integration-frontends/integration/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';
import { put, select, take } from 'typed-redux-saga';
import { pageLoaded, pageLoadError, selectRehydrated } from '@integration-frontends/common/app';

function* handler(action: ReturnType<typeof showPageEntered>) {
  try {
    const brandfolderRepo: IBrandfolderRepo = DI_CONTAINER.get(BRANDFOLDER_REPO_TOKEN);
    const containerRepo: IContainerRepo = DI_CONTAINER.get(CONTAINER_REPO_TOKEN);
    const collectionRepo: ICollectionRepo = DI_CONTAINER.get(COLLECTION_REPO_TOKEN);
    const { containerId, searchParams, sortOptions } = action.payload;
    const container = yield call(containerRepo.getContainer, containerId);
    yield initSearch(container, searchParams, sortOptions);

    if (container.type === ResourceType.BRANDFOLDER) {
      yield put(brandfolderEntityActions.brandfoldersReceived([container]));
      const collections = yield call(collectionRepo.listCollections);
      yield put(collectionEntityActions.collectionsReceived(collections));
    } else {
      const brandfolder = yield call(brandfolderRepo.getBrandfolder, container.brandfolderId);

      yield put(brandfolderEntityActions.brandfoldersReceived([brandfolder]));
      yield put(collectionEntityActions.collectionsReceived([container]));
    }

    yield put(showPageDataLoaded());
    yield put(pageLoaded());
  } catch (e) {
    yield put(pageLoadError());
  }
}

function* initSearch(
  container: Container,
  searchParams: SearchParameters,
  sortOptions: SortOptions,
) {
  yield put(searchAssetsActions.init({ container }));
  yield take(searchAssetsActions.initSuccess);
  yield put(searchAssetsActions.search({ searchParams, sortOptions }));
}

export function* enteredShowPageEffects() {
  const showPageEnteredAction = yield take(showPageEntered);
  const isRehydrated = yield select(selectRehydrated);

  if (isRehydrated) {
    // use cached data
    yield put(showPageDataLoaded());
  } else {
    yield handler(showPageEnteredAction);
  }

  yield takeEvery(showPageEntered, handler);
}
