import {
  deselectAllAttachments,
  entitiesSelectors,
  selectAllAttachments,
  selectAttachmentSelectors,
} from '@integration-frontends/integration/core/application';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import './multi-select-overlay.scss';
import { useMediaTypeSupport } from '../common/use-media-type-support';
import { MultiSelectActionsMenu } from '../multi-select-actions-menu';
import { ALL_VISIBLE_KEY, NONE_KEY } from '../attachment-selector/i18n';
import { NotificationsContainer } from '../common/notifications/notifications';
import { INTEGRATION_COMMON_NAMESPACE } from '../common/i18n';

export function MultiSelectOverlay() {
  const dispatch = useDispatch();
  const attachmentEntitiesList = useSelector(entitiesSelectors.attachment.selectEntities);
  const { exportableMediaTypes } = useMediaTypeSupport();
  const selectedAttachments = useSelector(selectAttachmentSelectors.selectedAttachments);
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);

  const selectAll = () => {
    const supportedAttachments = Object.keys(attachmentEntitiesList).reduce((supported, key) => {
      if(exportableMediaTypes.includes(attachmentEntitiesList[key].mediaType)) {
        supported.push(attachmentEntitiesList[key]);
      }

      return supported;
    }, []);

    dispatch(selectAllAttachments({attachments: supportedAttachments}));
  };

  const deselectAll = () => {
    dispatch(deselectAllAttachments());
  };

  return (
    <section
      className={`multi-select-overlay ${selectedAttachments.length ? 'open' : ''}`}
      id={'multi-select-overlay'}
    >
      <NotificationsContainer placement="above" location="multi-select" bottom={140} />
      <div
        className="flex flex-row justify-between items-center justify-between"
        style={{ width: '97vw' }}
      >
        <div className="flex flex-row items-center">
          <p className="font-medium">Select:</p>
          <p
            onClick={selectAll}
            className="font-bold link multi-select-overlay__link cursor-pointer"
          >
            {t(ALL_VISIBLE_KEY)}
          </p>
          <p
            onClick={deselectAll}
            className="font-bold link multi-select-overlay__link cursor-pointer"
          >
            {t(NONE_KEY)}
          </p>
        </div>
        <div className="flex flex-row items-center">
          <MultiSelectActionsMenu />
        </div>
      </div>
    </section>
  );
}
