import {
  addOrientationFilter,
  AttachmentOrientation,
  removeOrientationFilter,
  SearchParameters,
} from '@integration-frontends/integration/core/model';
import React, { FunctionComponent } from 'react';
import {
  OrientationFiltersComponent,
  OrientationFiltersComponentProps,
} from '@integration-frontends/integration/ui/attachment-selector/components/advanced-filters/components/orientation-filters/orientation-filters-component';

export interface OrientationFiltersContainerProps {
  searchParams: SearchParameters;
  onChange: (searchParams: SearchParameters) => void;
  Component?: FunctionComponent<OrientationFiltersComponentProps>;
}

export function OrientationFiltersContainer({
  searchParams,
  onChange,
  Component = OrientationFiltersComponent,
}: OrientationFiltersContainerProps) {
  function changed(orientation: AttachmentOrientation, checked: boolean) {
    if (checked) {
      onChange(addOrientationFilter(searchParams, orientation));
    } else {
      onChange(removeOrientationFilter(searchParams, orientation));
    }
  }

  return <Component searchParams={searchParams} onChange={changed} />;
}
