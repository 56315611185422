import { Identity, IIdentityStore } from '@integration-frontends/common/auth/core/model';

export class IdentityStoreMock implements IIdentityStore {
  get(): Promise<Identity> {
    return Promise.resolve(undefined);
  }

  set(identity: Identity): Promise<void> {
    return Promise.resolve(undefined);
  }
}
