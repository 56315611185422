import { SectionContent, SectionHeader } from '../../common';
import {
  CUSTOM_FIELDS_KEY,
  KEY_KEY,
  SELECT_KEY_KEY,
  SELECT_VALUE_KEY,
  VALUE_KEY,
} from '../../../../i18n';
import { BFLabel, BFSelect, IconClose } from '@integration-frontends/common/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { INTEGRATION_COMMON_NAMESPACE } from '../../../../../common/i18n';
import {
  ContainerCustomField,
  CustomFieldFilter,
} from '@integration-frontends/integration/core/model';
import { containerSelectors } from '@integration-frontends/integration/core/application';
import { useSelector } from 'react-redux';

export interface CustomFieldsFiltersComponentProps {
  customFieldFilters: CustomFieldFilter[];
  customFields: ContainerCustomField[];
  onFilterRemove: (id: string) => void;
  onKeySelect: (fieldId: string) => void;
  onValueSelect: (fieldId: string) => void;
  selectedFieldId: string;
}

export function CustomFieldsFiltersComponent({
  customFieldFilters,
  customFields,
  onFilterRemove,
  onKeySelect,
  onValueSelect,
  selectedFieldId,
}: CustomFieldsFiltersComponentProps) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);

  return (
    <div>
      <SectionHeader>
        <span>{t(CUSTOM_FIELDS_KEY)}</span>
      </SectionHeader>

      <SectionContent className="pb-lg">
        {customFieldFilters.length > 0 && (
          <div className="mb-md">
            {customFieldFilters.map((filter) => (
              <div key={filter.customField.id} className="flex justify-between mb-sm">
                <span>{filter.customField.name}</span>
                <span>{filter.value}</span>
                <IconClose
                  className="cursor-pointer"
                  onClick={() => onFilterRemove(filter.customField.id)}
                />
              </div>
            ))}
          </div>
        )}

        <div className="flex flex-col mb-sm">
          <BFLabel>{t(KEY_KEY)}</BFLabel>
          <BFSelect
            required
            placeholder={t(SELECT_KEY_KEY)}
            data-testid="custom-fields-key-selector"
            options={customFields.map((field) => ({ label: field.name, value: field.id }))}
            value={selectedFieldId}
            onOptionChange={(option) => onKeySelect(option?.value as string)}
            className="text-base"
          />
        </div>

        {selectedFieldId && (
          <CustomFieldsFiltersSelect
            field={customFields.find((field) => field.id === selectedFieldId)}
            onOptionChange={(option) => onValueSelect(option?.value as string)}
          />
        )}
      </SectionContent>
    </div>
  );
}

const CustomFieldsFiltersSelect = ({ field, onOptionChange }) => {
  const options = useSelector(
    containerSelectors.customFieldValues.selectValuesByCustomFieldId(field.id),
  );
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);

  return (
    <div className="flex flex-col">
      <BFLabel>{t(VALUE_KEY)}</BFLabel>
      <BFSelect
        placeholder={t(SELECT_VALUE_KEY)}
        data-testid="custom-fields-value-selector"
        options={options.map((option) => ({ label: option.value, value: option.value }))}
        onOptionChange={onOptionChange}
        className="text-base"
      />
    </div>
  );
};
