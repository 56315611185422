import { AssetsListPagination, getPageCeil, getPageFloor } from '@integration-frontends/integration/core/application/common/assets-list/assets-list-state/assets-list-pagination';
import { INTEGRATION_COMMON_NAMESPACE } from '@integration-frontends/integration/ui/common/i18n';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ASSETS_KEY, OF_KEY } from '../../i18n';

export function CurrentPageDisplay({ pagination }: { pagination: AssetsListPagination }) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);

  return (
    <span className="font-medium" data-testid="current-page-display">
      {getPageFloor(pagination)}-{getPageCeil(pagination)} {t(OF_KEY)}{' '}
      {pagination.totalCount.toLocaleString()} {t(ASSETS_KEY)}
    </span>
  );
}