import {
  Asset,
  AssetCustomFieldValue,
  AssetTag,
  Attachment,
  Brandfolder,
  BrandfolderTag,
  Collection,
  ContainerCustomField,
  CustomFieldValueOption,
  ContainerFileTypeAggregates,
  ContainerTag,
  CustomFieldValue,
  Label,
  Organization,
  SearchFilter,
  Section,
} from '@integration-frontends/integration/core/model';
import { EntityState } from '@reduxjs/toolkit';

export interface IntegrationEntitiesState {
  attachments: EntityState<Attachment>;
  assetCustomFieldValues: EntityState<AssetCustomFieldValue>;
  assets: EntityState<Asset>;
  assetTags: EntityState<AssetTag>;
  brandfolders: EntityState<Brandfolder>;
  containerCustomFields: EntityState<ContainerCustomField>;
  containerCustomFieldValues: EntityState<CustomFieldValueOption>;
  containerFileTypeAggregates: EntityState<ContainerFileTypeAggregates>;
  containerTags: EntityState<ContainerTag>;
  collections: EntityState<Collection>;
  customFieldValues: EntityState<CustomFieldValue>;
  labels: EntityState<Label>;
  organizations: EntityState<Organization>;
  searchFilters: EntityState<SearchFilter>;
  sections: EntityState<Section>;
}
