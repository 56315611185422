import React, { HTMLAttributes } from 'react';
import { FlexSpacer } from '@integration-frontends/integration/ui/attachment-selector/common/layout/flex-spacer';
import { BFLabel } from '@integration-frontends/common/ui';

export interface FormControlProps {
  label?: string;
}

export function FormControl({
  children,
  label,
  ...restProps
}: FormControlProps & HTMLAttributes<HTMLDivElement>) {
  return (
    <FlexSpacer direction={'vertical'} size={'xs'} {...restProps}>
      {label && <BFLabel>{label}</BFLabel>}
      {children}
    </FlexSpacer>
  );
}
