import { withFormControlStyles } from '@integration-frontends/common/ui/forms/form-controls/common';
import React, { HTMLAttributes, InputHTMLAttributes } from 'react';
import { format } from 'date-fns';
import {
  COMPONENT_RESOLVER_TOKEN,
  ComponentResolver,
  DI_CONTAINER,
} from '@integration-frontends/core';
import './date-picker.scss';

export const DATE_PICKER_COMPONENT_KEY = 'DATE_PICKER';

export type BFDatePickerProps = Omit<HTMLAttributes<HTMLInputElement>, 'value'> &
  Omit<InputHTMLAttributes<HTMLInputElement>, 'value'> & { value: Date | string };

function DefaultDatePicker({ value, ...restProps }: BFDatePickerProps) {
  return <input type="date" value={format(value, 'YYYY-MM-DD')} {...restProps} />;
}

export const BFDatePicker = withFormControlStyles<BFDatePickerProps>((props) => {
  const componentResolver: ComponentResolver = DI_CONTAINER.get(COMPONENT_RESOLVER_TOKEN);
  const Component = componentResolver.getComponent(DATE_PICKER_COMPONENT_KEY) || DefaultDatePicker;
  return <Component {...props} />;
});
