import { StateSelector } from '@integration-frontends/common/utils/redux';
import { searchFilterAdapter } from '@integration-frontends/integration/core/application/common/entities-state/entities/search-filter';
import { Container, SearchFilter } from '@integration-frontends/integration/core/model';
import { createSelector, EntityState } from '@reduxjs/toolkit';
import { compose, equals, prop } from 'ramda';

export function createSearchFilterSelectors(
  stateSelector: StateSelector<EntityState<SearchFilter>>,
) {
  const baseSelectors = searchFilterAdapter.getSelectors(stateSelector);
  const byContainer = (container: Container) =>
    createSelector(baseSelectors.selectAll, (entities) =>
      entities.filter(compose(equals(container?.id), prop('containerId'))),
    );

  return {
    ...baseSelectors,
    byContainer,
  };
}
