import { ResourceBase, ResourceType } from '@integration-frontends/integration/core/model';
import { CustomFieldValueOption } from '@integration-frontends/integration/core/model';

export interface Collection extends ResourceBase {
  type: ResourceType.COLLECTION;
  brandfolderId: string;
  assetCount: number;
  sectionIds?: string[];
  name: string;
  hasAccess: boolean;
}

export interface CollectionCustomField extends ResourceBase {
  type: ResourceType.CUSTOM_FIELD;
  collectionId: string;
  allowedValues: string[];
  prioritized: boolean;
  searchable: boolean;
}

export interface CollectionTag extends ResourceBase {
  type: ResourceType.TAG;
  collectionId: string;
  count: number;
  searchable: boolean;
}

export interface CollectionFileTypeAggregates {
  collectionId: string;
  name: string;
  count: number;
}

export const COLLECTION_REPO_TOKEN = 'COLLECTION_REPO';
export interface ICollectionRepo {
  getCollection: (
    collectionId: string,
  ) => Promise<Collection>;
  listCollections: () => Promise<Collection[]>;
  getCollectionCustomFields: (collectionId: string) => Promise<CollectionCustomField[]>;
  getCollectionCustomFieldValues: (collectionId: string, customFieldKey: string) => Promise<CustomFieldValueOption[]>;
  getCollectionTags: (collectionId: string) => Promise<CollectionTag[]>;
  getCollectionFileTypeAggregates: (
    collectionId: string,
  ) => Promise<CollectionFileTypeAggregates[]>;
}
