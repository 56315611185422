import {
  collectionEntitySelectors,
  containerSelectors,
} from '@integration-frontends/integration/core/application';
import { Collection, Brandfolder, ResourceType } from '@integration-frontends/integration/core/model';
import React, { HTMLAttributes, useState } from 'react';
import { useSelector } from 'react-redux';
import { usePopper } from 'react-popper';
import { Popover } from '@headlessui/react';
import { CollectionsToggle, ContainersCompactList } from '../../common';

export interface CollectionsSelectorToggleProps {
  containerId: string;
  open: boolean;
}

export function CollectionsSelectorToggle({
  containerId,
  open,
  ...props
}: CollectionsSelectorToggleProps & HTMLAttributes<HTMLDivElement>) {
  const selectedContainer = useSelector((state) =>
    containerSelectors.selectById(state, containerId),
  );

  const collections = useSelector(
    collectionEntitySelectors.byBrandfolderId(
      selectedContainer?.type === ResourceType.BRANDFOLDER
        ? selectedContainer?.id
        : selectedContainer?.brandfolderId,
    ),
  );

  return collections.length > 0 ? (
    <CollectionsToggle collectionCount={collections.length} open={open} primary={true} {...props} />
  ) : null;
}

export interface CollectionsSelectorContentProps {
  containerId: string;
  onCollectionSelect: (collectionId: Collection) => void;
  id?: string;
}

export function CollectionsSelectorContent({
  containerId,
  onCollectionSelect,
  id,
}: CollectionsSelectorContentProps) {
  const selectedContainer = useSelector((state) =>
    containerSelectors.selectById(state, containerId),
  );

  const collections = useSelector(
    collectionEntitySelectors.byBrandfolderId(
      selectedContainer?.type === ResourceType.BRANDFOLDER
        ? selectedContainer.id
        : selectedContainer.brandfolderId,
    ),
  );

  return <ContainersCompactList id={id} containerId={containerId} containers={collections} containerSelect={onCollectionSelect} listItemBackgroundHover={true} />;
}
