import { SetApiKey } from '@integration-frontends/common/auth/ui';
import { Loader } from '@integration-frontends/common/ui';
import React, { Suspense, useEffect } from 'react';
import { Provider } from 'react-redux';
import { Navigate, Route, Routes, unstable_HistoryRouter as Router } from 'react-router-dom';
import { init } from '@integration-frontends/common/app';
import { loadSegment } from '@integration-frontends/common/analytics';
import { environment } from '../environments/environment';
import { AttachmentEditorPage, SelectAttachmentPage } from './ui';
import {
  BFHistory,
  BFStore,
  DI_CONTAINER,
  HISTORY_TOKEN,
  STORE_TOKEN,
} from '@integration-frontends/core';

export function App() {
  const history = DI_CONTAINER.get<BFHistory>(HISTORY_TOKEN);
  const store = DI_CONTAINER.get<BFStore>(STORE_TOKEN);

  useEffect(() => {
    store.dispatch(init({ name: 'integration-sfmc' }));
    loadSegment(environment);
  }, []);

  console.log(history);
  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <Provider store={store}>
        <Router history={history}>
          <Suspense
            fallback={
              <div className="w-full h-full flex items-center justify-center">
                <Loader />
              </div>
            }
          >
            <Routes>
              <Route path="/welcome" element={<SetApiKey />} />
              <Route path="/select-attachment/*" element={<SelectAttachmentPage />} />
              <Route path="/edit-attachment/:attachmentId" element={<AttachmentEditorPage />} />
              <Route path="*" element={<Navigate to={'/select-attachment'} replace />} />
            </Routes>
          </Suspense>
        </Router>
      </Provider>
    </div>
  );
}

export default App;
