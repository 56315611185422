// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

export const environment = {
  production: false,
  bfBaseUrl: 'https://bf-stage.com/api',
  bfWebsiteBaseUrl: 'https://bf-stage.com',
  bfStaticAssetsBaseUrl: 'https://static-fast.brandfolder.com',
  segmentWriteKey: '3D2vwp7cRNdeQQAR0agYzuEH3oAi3XWA',
};
