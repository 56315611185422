import {
  CheckboxListContainer,
  CheckboxListItemContainer,
  SectionContent,
  SectionHeader,
} from '@integration-frontends/integration/ui/attachment-selector/components/advanced-filters/common';
import {
  SEARCH_FILE_TYPES_KEY,
  TOP_FILE_TYPES_KEY,
} from '@integration-frontends/integration/ui/attachment-selector/i18n';
import { BFCheckbox, BFSearchInput, InputSize } from '@integration-frontends/common/ui';
import {
  ContainerFileTypeAggregates,
  hasFileTypeFilter,
  SearchParameters,
} from '@integration-frontends/integration/core/model';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { INTEGRATION_COMMON_NAMESPACE } from '@integration-frontends/integration/ui/common/i18n';

export interface TopFileTypesFiltersComponentProps {
  fileTypeAggregates: ContainerFileTypeAggregates[];
  searchParams: SearchParameters;
  fileTypeToggle: (fileType: string) => void;
}

export function TopFileTypesFiltersComponent({
  fileTypeAggregates,
  searchParams,
  fileTypeToggle,
}: TopFileTypesFiltersComponentProps) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const [search, setSearch] = useState('');

  return (
    <fieldset>
      <SectionHeader>
        <legend className="text-primary">{t(TOP_FILE_TYPES_KEY)}</legend>
      </SectionHeader>

      <SectionContent className="pb-lg">
        <CheckboxListContainer className="mb-xl">
          {fileTypeAggregates
            .filter(
              (fileType) =>
                search === '' || fileType.name.toLowerCase().includes(search.toLowerCase()),
            )
            .sort((agg1, agg2) => agg2.count - agg1.count)
            .slice(0, 10)
            .map((fileType) => (
              <CheckboxListItemContainer key={fileType.name}>
                <div
                  className="flex justify-between cursor-pointer"
                  onClick={() => fileTypeToggle(fileType.name)}
                >
                  <BFCheckbox
                    value={fileType.name}
                    checked={hasFileTypeFilter(searchParams, fileType.name)}
                    style={{ marginRight: 10 }}
                    label={fileType.name.toLocaleUpperCase()}
                    readOnly
                  />

                  <span className="text-gray">{fileType.count}</span>
                </div>
              </CheckboxListItemContainer>
            ))}
        </CheckboxListContainer>

        <BFSearchInput
          className="w-full"
          size={InputSize.Small}
          placeholder={t(SEARCH_FILE_TYPES_KEY)}
          onChange={(e) => setSearch(e.target.value)}
        />
      </SectionContent>
    </fieldset>
  );
}
