import { StateSelector } from '@integration-frontends/common/utils/redux';
import { createSelector } from '@reduxjs/toolkit';
import { SelectAttachmentState } from './reducer';

const selectInitLoading = (state: SelectAttachmentState) => state.initLoading;
const selectInitSuccess = (state: SelectAttachmentState) => state.initSuccess;
const selectPageLoading = (state: SelectAttachmentState) => state.pageLoading || state.initLoading;
//multi select list
const selectSelectedAttachmentsList = (state: SelectAttachmentState) =>
  state.selectedAttachments;
//selection type
const selectSelectionType = (state: SelectAttachmentState) => state.selectionType;

//show page
const selectShowPageScrollPosition = (state: SelectAttachmentState) => state.showPageScrollPosition;

// TODO: make this reusable and less verbose ~PP
export type SelectAttachmentStateSelector = StateSelector<SelectAttachmentState>;

export function createSelectAttachmentSelectors(
  stateSelector: SelectAttachmentStateSelector,
) {
  const baseSelectors = {
    initLoading: createSelector(stateSelector, selectInitLoading),
    initSuccess: createSelector(stateSelector, selectInitSuccess),
    pageLoading: createSelector(stateSelector, selectPageLoading),
    selectedAttachments: createSelector(stateSelector, selectSelectedAttachmentsList),
    selectionType: createSelector(stateSelector, selectSelectionType),
    showPageScrollPosition: createSelector(stateSelector, selectShowPageScrollPosition),
  };

  return {
    ...baseSelectors,
  };
}
