import { DI_CONTAINER } from '@integration-frontends/core';
import {
  ASSET_REPO_TOKEN,
  ATTACHMENT_REPO_TOKEN,
  IAssetRepo,
  IAttachmentRepo,
} from '@integration-frontends/integration/core/model';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  assetCustomFieldValueEntityActions,
  assetTagEntityActions,
  attachmentEntityActions,
  IntegrationEntitiesSelectors,
} from '../../../common';
import { attachmentDataLoaded, enteredAttachmentDetails } from '../actions';
import { pageLoadError } from '@integration-frontends/common/app';

const handler = function* (
  entitiesSelectors: IntegrationEntitiesSelectors,
  action: ReturnType<typeof enteredAttachmentDetails>,
) {
  try {
    const attachmentRepo: IAttachmentRepo = DI_CONTAINER.get(ATTACHMENT_REPO_TOKEN);
    const assetRepo: IAssetRepo = DI_CONTAINER.get(ASSET_REPO_TOKEN);

    const attachment = yield call(attachmentRepo.getAttachment, action.payload.attachmentId);
    const assetDetails = yield call(assetRepo.getAssetDetails, attachment.assetId);

    yield put(assetTagEntityActions.assetTagsReceived(assetDetails.tags));
    yield put(
      assetCustomFieldValueEntityActions.assetCustomFieldValuesReceived(
        assetDetails.customFieldValues,
      ),
    );
    yield put(attachmentEntityActions.attachmentReceived(attachment));
    yield put(attachmentDataLoaded());
  } catch (e) {
    yield put(pageLoadError());
  }
};

export function* goToAttachmentDetailsEffects(entitiesSelectors: IntegrationEntitiesSelectors) {
  yield takeEvery(enteredAttachmentDetails, handler, entitiesSelectors);
}
