import { assetCustomFieldValueEntitySelectors } from '@integration-frontends/integration/core/application';
import { Asset } from '@integration-frontends/integration/core/model';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { INTEGRATION_COMMON_NAMESPACE } from '../../../common/i18n';
import { PageSection, PageSectionContent } from '../../../common/layout/page';

import { AssetCustomFieldValues } from '../../common/asset-custom-field-values';
import { CUSTOM_FIELDS_KEY } from '../../i18n';

export interface CustomFieldsProps {
  asset: Asset;
}

export function CustomFields({ asset }: CustomFieldsProps) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const customFieldValues = useSelector(assetCustomFieldValueEntitySelectors.byAssetId(asset.id));

  return (
    <>
      {customFieldValues.length > 0 && (
        <PageSection title={t(CUSTOM_FIELDS_KEY)}>
          <PageSectionContent>
            <AssetCustomFieldValues customFieldValues={customFieldValues} />
          </PageSectionContent>
        </PageSection>
      )}
    </>
  );
}
