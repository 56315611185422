import { filterBy } from '@integration-frontends/common/utils/collection';
import { AbsoluteDimensions, MediaType, Offsets } from '../../common';
import { ResourceBase, ResourceType } from '../resource-base';

export enum AttachmentOrientation {
  Horizontal,
  Vertical,
  Square,
  Panoramic,
}

export enum AttachmentUploadDate {
  Last30Minutes = 'LAST_30_MINUTES',
  Past24Hours = 'PAST_24_HOURS',
  Past7Days = 'PAST_7_DAYS',
  DateRange = 'DATE_RANGE',
}

export interface Attachment extends ResourceBase {
  type: ResourceType.ATTACHMENT;
  url: string;
  filename: string;
  extension: string;
  mimetype: string;
  assetId: string;
  thumbnailUrl: string;
  dimensions: AbsoluteDimensions;
  mediaType: MediaType;
  supported: boolean;
  sizeInBytes: number;
  updatedAt: Date;
  createdAt: Date;
}

export const filterByAsset = filterBy('assetId');
export function getAttachmentUrl(attachment: Attachment | AttachmentVariant): string {
  return attachment.url;
}

export interface AttachmentVariant extends Omit<Attachment, 'type'> {
  type: ResourceType.ATTACHMENT_VARIANT;
  attachmentId: string;
}

export interface AttachmentTransforms {
  crop?: AbsoluteDimensions & Offsets;
  size?: AbsoluteDimensions;
  mediaType?: MediaType;
}
