import { IconEmbed } from '@integration-frontends/common/ui';
import { IconPlacementOptions } from '@integration-frontends/common/ui/icons/icon-placement-options';
import { DI_CONTAINER } from '@integration-frontends/core';
import {
  assetDetailsPageEntered,
  assetEntitySelectors,
  attachmentEntitySelectors,
  containerSelectors,
  IntegrationRootState,
} from '@integration-frontends/integration/core/application';
import { Attachment, positionComparator } from '@integration-frontends/integration/core/model';
import { AttachmentSelectorFeaturesService } from '@integration-frontends/integration/infrastructure/isomorphic';
import {
  ATTACHMENT_SELECTOR_FEATURES_TOKEN,
  AttachmentActionProviderContext,
  AttachmentSelectorFeature,
  createBasicActionContainer,
} from '@integration-frontends/integration/ui';
import {
  PageContainer,
  PageContent,
  PageSection,
  PageSectionContent,
  PageSubSection,
} from '@integration-frontends/integration/ui/common/layout/page';
import { AssetDetailsContainer } from '@integration-frontends/integration/ui/attachment-selector/components/asset-details-page/asset-details/asset-details-container';
import { AttachmentPreview } from '@integration-frontends/integration/ui/attachment-selector/components/asset-details-page/attachment-preview';
import { CdnEmbedOptionsContainer } from '@integration-frontends/integration/ui/attachment-selector/components/asset-details-page/cdn-embed-options/cdn-embed-options-container';
import { PlacementOptionsContainer } from '@integration-frontends/integration/ui/attachment-selector/components/asset-details-page/placement-options/placement-options-container';
import {
  CDN_EMBED_TITLE_KEY,
  PLACEMENT_OPTIONS_KEY,
} from '@integration-frontends/integration/ui/attachment-selector/i18n';
import { INTEGRATION_COMMON_NAMESPACE } from '@integration-frontends/integration/ui/common/i18n';
import * as qs from 'qs';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { AssetAttachments } from './asset-attachments/asset-attachments';
import { AssetDescription } from './asset-description';
import './asset-details-page.scss';
import { AssetTags } from './asset-tags';
import { CustomFields } from './custom-fields';
import { AssetDetailsPageHeader } from './header';

export interface AssetDetailsPageProps {
  assetSelect: (assetId: string) => void;
}

export function AssetDetailsPage({ assetSelect }: AssetDetailsPageProps) {
  const dispatch = useDispatch();
  const location = useLocation();
  const { assetId, containerId } = useParams<{ assetId: string; containerId: string }>();
  const featuresService: AttachmentSelectorFeaturesService = DI_CONTAINER.get(
    ATTACHMENT_SELECTOR_FEATURES_TOKEN,
  );
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const urlSearch = qs.parse(location.search.substr(1)) as {
    selectedAttachmentId?: string;
  };

  const selectedContainer = useSelector((state) =>
    containerSelectors.selectById(state, containerId),
  );
  const asset = useSelector((state: IntegrationRootState) =>
    assetEntitySelectors.selectById(state, assetId),
  );
  const attachments = useSelector(attachmentEntitySelectors.byAssetId(assetId)).sort(
    positionComparator,
  );
  const [selectedAttachmentId, setSelectedAttachmentId] = useState(null);
  const selectedAttachment = attachments.find(
    (attachment) => attachment.id === selectedAttachmentId,
  );
  const placementOptionsSectionRef = useRef(null);
  const cdnEmbedSectionRef = useRef(null);

  useEffect(() => {
    assetSelect && assetSelect(assetId);
    dispatch(assetDetailsPageEntered({ assetId }));
  }, [assetId]);

  useEffect(() => {
    if (!selectedAttachmentId && urlSearch.selectedAttachmentId) {
      setSelectedAttachmentId(urlSearch.selectedAttachmentId);
    } else if (!selectedAttachmentId && attachments?.[0]) {
      setSelectedAttachmentId(attachments[0].id);
    }
  }, [attachments]);

  const attachmentSelected = useCallback(
    (attachment: Attachment) => {
      setSelectedAttachmentId(attachment.id);
    },
    [assetId],
  );

  const OpenPlacementOptionsActionContainer = createBasicActionContainer(
    'OPEN_PLACEMENT_OPTIONS',
    t(PLACEMENT_OPTIONS_KEY),
    ([attachment]) => {
      setSelectedAttachmentId(attachment.id);
      placementOptionsSectionRef?.current.open();
      setTimeout(() => placementOptionsSectionRef?.current.scrollIntoView(), 200);
    },
    IconPlacementOptions,
  );

  const OpenCdnEmbedOptionsActionContainer = createBasicActionContainer(
    'OPEN_CDN_EMBED',
    t(CDN_EMBED_TITLE_KEY),
    ([attachment]) => {
      setSelectedAttachmentId(attachment.id);
      cdnEmbedSectionRef?.current.open();
      setTimeout(() => cdnEmbedSectionRef?.current.scrollIntoView(), 200);
    },
    IconEmbed,
  );

  if (!asset) return null;

  return (
    <PageContainer
      data-testid="asset-details-page"
      aria-modal="true"
      className="h-full overflow-y-auto"
      role="dialog"
    >
      <AssetDetailsPageHeader asset={asset} container={selectedContainer} />

      <PageContent>
        <PageSection id="asset-details-section">
          <PageSubSection title={asset.name}>
            <PageSectionContent>
              <AttachmentActionProviderContext.Provider
                value={{
                  actionContainers: [
                    ...(featuresService.hasFeature(AttachmentSelectorFeature.PlacementOptions)
                      ? [OpenPlacementOptionsActionContainer]
                      : []),
                    ...(featuresService.hasFeature(AttachmentSelectorFeature.CdnEmbed)
                      ? [OpenCdnEmbedOptionsActionContainer]
                      : []),
                  ],
                }}
              >
                <AssetAttachments
                  asset={asset}
                  containerId={selectedContainer?.id}
                  onAttachmentClick={attachmentSelected}
                  selectedAttachmentId={selectedAttachmentId}
                />
              </AttachmentActionProviderContext.Provider>
            </PageSectionContent>
          </PageSubSection>

          <PageSectionContent>
            <AttachmentPreview attachment={selectedAttachment} />
          </PageSectionContent>
        </PageSection>

        {featuresService.hasFeature(AttachmentSelectorFeature.PlacementOptions) && (
          <PageSection
            ref={placementOptionsSectionRef}
            data-testid="placement-options-section"
            expandable={true}
            initialExpanded={false}
            title={t(PLACEMENT_OPTIONS_KEY)}
          >
            <PageSectionContent>
              <PlacementOptionsContainer attachment={selectedAttachment} />
            </PageSectionContent>
          </PageSection>
        )}

        {featuresService.hasFeature(AttachmentSelectorFeature.CdnEmbed) && (
          <PageSection
            ref={cdnEmbedSectionRef}
            data-testid="cdn-embed-options-section"
            expandable={true}
            initialExpanded={false}
            title={t(CDN_EMBED_TITLE_KEY)}
          >
            <PageSectionContent>
              <CdnEmbedOptionsContainer attachment={selectedAttachment} />
            </PageSectionContent>
          </PageSection>
        )}

        <AssetDetailsContainer asset={asset} attachment={selectedAttachment} />

        <AssetDescription asset={asset} />

        <CustomFields asset={asset} />

        <AssetTags asset={asset} />
      </PageContent>
    </PageContainer>
  );
}
