import { ResourceBase, ResourceType } from '@integration-frontends/integration/core/model';

export interface CustomFieldValue extends ResourceBase {
  type: ResourceType.CUSTOM_FIELD_VALUE;
  key: string;
  value: string;
}

export interface CustomFieldValueOption {
  customFieldKey: string;
  id: string;
  type: ResourceType.CUSTOM_FIELD_VALUE_OPTION;
  value: string,
}
