import { withFormControlStyles } from '@integration-frontends/common/ui/forms/form-controls/common';
import React, { HTMLAttributes, InputHTMLAttributes } from 'react';
import {
  COMPONENT_RESOLVER_TOKEN,
  ComponentResolver,
  DI_CONTAINER,
} from '@integration-frontends/core';
import { isString } from 'lodash';
import classNames from 'classnames';

export const CHECKBOX_COMPONENT_KEY = 'CHECKBOX';
export type BFCheckboxProps = {
  label: string;
  labelClassName?: HTMLAttributes<HTMLLabelElement>['className'];
} & HTMLAttributes<HTMLInputElement> &
  InputHTMLAttributes<HTMLInputElement>;

function DefaultCheckbox({ label, className, labelClassName, ...restProps }: BFCheckboxProps) {
  return (
    <label style={{ display: 'flex', alignItems: 'center' }} className="min-w-0">
      <input
        type="checkbox"
        className={classNames(
          restProps.disabled
            ? `bf-checkbox rounded disabled`
            : `bf-checkbox border-bf-light-gray rounded`,
          className,
        )}
        style={{ marginRight: 10 }}
        {...restProps}
      />
      {isString(label) ? (
        <span className={classNames('flex-grow min-w-0 cursor-pointer truncate', labelClassName)}>
          {label}
        </span>
      ) : (
        <>{label}</>
      )}
    </label>
  );
}

export const BFCheckbox = withFormControlStyles<BFCheckboxProps>((props) => {
  const componentResolver: ComponentResolver = DI_CONTAINER.get(COMPONENT_RESOLVER_TOKEN);
  const CheckboxComponent =
    componentResolver.getComponent(CHECKBOX_COMPONENT_KEY) || DefaultCheckbox;
  return <CheckboxComponent {...props} />;
});
