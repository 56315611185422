import { ApiFetchDataResponse, CustomFieldKeyValueDto, GenericFileDto } from '@integration-frontends/common/brandfolder-api';
import {
  Asset,
  assetCustomFieldValuesMockData,
  AssetDetails,
  AssetsListResultSet,
  assetsMockData,
  assetTagsMockData,
  attachmentsMockData,
  Container,
  IAssetRepo,
  ListOptions,
  PagedResults,
  Section,
} from '@integration-frontends/integration/core/model';

export class AssetRepoMock implements IAssetRepo {
  create(container: Container, sectionId: string, name: string, files: File[]): Promise<void> {
    return Promise.resolve(undefined);
  }

  createExternalMedia(
    container: Container,
    sectionId: string,
    source: string,
    externalMedia: { name: string; url: string },
  ): Promise<ApiFetchDataResponse<GenericFileDto>> {
    return Promise.resolve(undefined);
  }

  addAssetTags(assetIds: string[], tags: string[]): Promise<void> {
    return;
  }

  addAssetCustomFields: (
    assetIds: string[],
    container: Container,
    customFields: CustomFieldKeyValueDto[],
  ) => Promise<void>;

  fetchAsset(assetId: string): Promise<Asset> {
    return Promise.resolve(assetsMockData[0]);
  }

  getAssetDetails(assetId: string): Promise<AssetDetails> {
    return Promise.resolve({
      tags: assetTagsMockData,
      customFieldValues: assetCustomFieldValuesMockData,
    });
  }

  listBrandfolderAssets(
    brandfolderId: string,
    options: ListOptions | undefined,
  ): Promise<AssetsListResultSet> {
    return Promise.resolve({
      assets: assetsMockData,
      attachments: attachmentsMockData,
    });
  }

  listCollectionAssets(
    collectionId: string,
    options: ListOptions | undefined,
  ): Promise<AssetsListResultSet> {
    return Promise.resolve({
      assets: assetsMockData,
      attachments: attachmentsMockData,
    });
  }

  listCollectionSectionAssets(
    collectionId: string,
    sectionId: string,
    options: ListOptions | undefined,
  ): Promise<PagedResults<AssetsListResultSet>> {
    return Promise.resolve(undefined);
  }

  listContainerSectionAssets(
    container: Container,
    sectionId: string,
    options: ListOptions | undefined,
  ): Promise<PagedResults<AssetsListResultSet>> {
    return Promise.resolve({
      data: {
        assets: assetsMockData,
        attachments: attachmentsMockData,
      },
      totalCount: assetsMockData.length,
      perPage: assetsMockData.length,
      currentPage: options.pagination.page || 1,
    });
  }

  listContainerSectionsAssets(
    container: Container,
    sections: Section[],
    options: ListOptions | undefined,
  ): Promise<{ sectionId: string; results: PagedResults<AssetsListResultSet> }[]> {
    return Promise.resolve([]);
  }

  listSectionAssets(
    sectionId: string,
    options: ListOptions | undefined,
  ): Promise<PagedResults<AssetsListResultSet>> {
    return Promise.resolve(undefined);
  }

  listContainerAssets(
    container: Container,
    options: ListOptions | undefined,
  ): Promise<PagedResults<AssetsListResultSet>> {
    return Promise.resolve({
      data: {
        assets: assetsMockData,
        attachments: attachmentsMockData,
      },
      totalCount: assetsMockData.length,
      perPage: assetsMockData.length,
      currentPage: options.pagination.page || 1,
    });
  }
}
