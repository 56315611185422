import { all, call, put, takeEvery } from 'typed-redux-saga';
import {
  brandfolderEntityActions,
  collectionEntityActions,
  orgLandingPageDataLoaded,
  orgLandingPageEntered,
} from '@integration-frontends/integration/core/application';
import {
  BRANDFOLDER_REPO_TOKEN,
  COLLECTION_REPO_TOKEN,
  IBrandfolderRepo,
  ICollectionRepo,
} from '@integration-frontends/integration/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';
import { pageLoaded, pageLoadError } from '@integration-frontends/common/app';
import { FieldParameter } from '@integration-frontends/common/brandfolder-api';

function* handler() {
  try {
    const brandfolderRepo: IBrandfolderRepo = DI_CONTAINER.get(BRANDFOLDER_REPO_TOKEN);
    const collectionRepo: ICollectionRepo = DI_CONTAINER.get(COLLECTION_REPO_TOKEN);

    const [brandfolders, collections] = yield all([
      call(brandfolderRepo.listBrandfolders),
      call(collectionRepo.listCollections, [FieldParameter.AssetCount]),
    ]);

    yield put(brandfolderEntityActions.brandfoldersReceived(brandfolders));
    yield put(collectionEntityActions.collectionsReceived(collections));
    yield put(orgLandingPageDataLoaded());
    yield put(pageLoaded());
  } catch (e) {
    yield put(pageLoadError());
  }
}

export function* enteredOrgLandingPageEffects() {
  yield takeEvery(orgLandingPageEntered, handler);
}
