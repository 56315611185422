import React, { ButtonHTMLAttributes, HTMLAttributes, useState } from 'react';
import classNames from 'classnames';
import { Popover } from '@headlessui/react';
import { usePopper } from 'react-popper';
import {
  BFButton,
  BFPanel,
  ButtonType,
  IconCaretDownWhite,
} from '@integration-frontends/common/ui';
import { Attachment, AttachmentVariant } from '@integration-frontends/integration/core/model';
import { useTranslation } from 'react-i18next';
import { INTEGRATION_COMMON_NAMESPACE } from '@integration-frontends/integration/ui/common/i18n';
import { useMediaTypeSupport } from '@integration-frontends/integration/ui/common/use-media-type-support';
import { useAttachmentActions } from '@integration-frontends/integration/ui/attachment-selector/common/attachment-actions/use-attachment-action';

function MenuItem(
  props: HTMLAttributes<HTMLButtonElement> & ButtonHTMLAttributes<HTMLButtonElement>,
) {
  return (
    <button
      {...props}
      disabled={props.disabled}
      className={classNames(
        'py-xs px-sm cursor-pointer hover:bg-bf-marshmallow-light',
        {
          'text-bf-light-gray cursor-default': props.disabled,
        },
        props.className,
      )}
    />
  );
}

export interface AttachmentDetailsActionsProps {
  selectAction: string;
  onSelection: () => void;
  attachment: Attachment | AttachmentVariant;
}
export function AttachmentDetailsActions({
  selectAction,
  onSelection,
  attachment,
}: AttachmentDetailsActionsProps) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { isSupported } = useMediaTypeSupport();
  const { attachmentActionContainers } = useAttachmentActions();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [{ name: 'offset', options: { offset: [0, 10] } }],
    strategy: 'absolute',
  });

  return attachmentActionContainers.length >= 1 ? (
    <Popover data-cy="popover-menu" style={{ zIndex: 100 }}>
      <Popover.Button as="span" ref={setReferenceElement} style={{ minWidth: '150px' }}>
        <BFButton
          data-testid="select-attachment-action"
          buttonType={ButtonType.Primary}
          className="w-full flex flex-row items-center justify-between"
          disabled={!isSupported(attachment.mediaType)}
          aria-label="attachment options"
        >
          {selectAction}
          <IconCaretDownWhite className="text-white" />
        </BFButton>
      </Popover.Button>
      <Popover.Panel ref={setPopperElement} style={styles.popper} {...attributes.popper}>
        <BFPanel className="flex flex-col bg-bf-white text-primary rounded-md border-none overflow-hidden whitespace-nowrap p-sm">
          {attachmentActionContainers.map((ActionContainer, idx) => {
            return (
              <ActionContainer
                selectedAttachments={[attachment]}
                key={idx}
                render={({ id, Icon, enabled, label, onClick }) => (
                  <MenuItem
                    data-testid={`attachment-action-${id}`}
                    className="flex flex-row justify-between"
                    onClick={onClick}
                    disabled={!enabled}
                  >
                    {Icon && <Icon />}
                    {label}
                  </MenuItem>
                )}
              />
            );
          })}
        </BFPanel>
      </Popover.Panel>
    </Popover>
  ) : (
    <BFButton
      buttonType={ButtonType.Primary}
      data-cy="select-attachment-action"
      className="attachments"
      data-is-focusable={true}
      onClick={onSelection}
      style={{ minWidth: '150px' }}
      disabled={!isSupported(attachment.mediaType)}
    >
      {t(selectAction)}
    </BFButton>
  );
}
