import {
  AttachmentPlacementDetailsContainer,
  BadgeSize,
  BadgeType,
  BFCheckbox,
  PillBadge,
} from '@integration-frontends/common/ui';
import { useDependencyInjector } from '@integration-frontends/core/di-container/react';
import {
  attachmentDeselected,
  attachmentSelected,
  ISelectAttachmentOptions,
  SELECT_ATTACHMENT_OPTIONS_TOKEN,
  selectAttachmentSelectors,
  SelectionType,
} from '@integration-frontends/integration/core/application';
import { Asset, Attachment } from '@integration-frontends/integration/core/model';
import { Tooltip, TooltipBody, useTooltip } from '@smartsheet/lodestar-core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AttachmentThumbnailImage } from '../../../../common/attachment-thumbnail-image';
import { useMediaTypeSupport } from '../../../../common/use-media-type-support';
import {
  INTEGRATION_UI_STYLING_OPTIONS_TOKEN,
  IntegrationUiStylingOptions,
} from '../../../../styling-options';
import { Thumbnail } from '../../thumbnail';
import { ThumbnailContainer, ThumbnailLabelContainer } from '../common';
import './attachment-thumbnail.scss';

export interface AttachmentThumbnailBaseProps {
  asset: Asset;
  attachment: Attachment;
  responsive?: boolean;
  useAssetName?: boolean;
  index?: number;
  overlay?: React.ReactNode;
}

export function AttachmentThumbnailBase({
  asset,
  attachment,
  responsive,
  useAssetName,
  index,
  overlay,
}: AttachmentThumbnailBaseProps) {
  const dispatch = useDispatch();
  const selectionOptions: ISelectAttachmentOptions = useDependencyInjector(
    SELECT_ATTACHMENT_OPTIONS_TOKEN,
  );
  const { isSupported } = useMediaTypeSupport();
  const selectionType: SelectionType = selectionOptions.selectionType;
  const selectedAttachments = useSelector(selectAttachmentSelectors.selectedAttachments);
  const selectedAttachmentIds = selectedAttachments.map((attachment) => attachment.id);
  const { tooltipProps, targetProps } = useTooltip({ placement: 'top-start' });
  const stylingOptions = useDependencyInjector<IntegrationUiStylingOptions>(
    INTEGRATION_UI_STYLING_OPTIONS_TOKEN,
  );

  const onSelection = () => {
    selectedAttachmentIds.includes(attachment.id)
      ? dispatch(attachmentDeselected({ attachment: attachment }))
      : dispatch(attachmentSelected({ attachment: attachment }));
  };

  if (!asset || !attachment) return null;

  return (
    <ThumbnailContainer
      responsive={responsive}
      className="attachment-thumbnail"
      data-cy="attachment-thumbnail"
    >
      <Thumbnail>
        <div
          data-testid="attachment-thumbnail"
          className="asset-thumbnail-content w-full flex items-center justify-center"
          style={{ height: 150, position: 'relative' }}
        >
          <AttachmentThumbnailImage attachment={attachment} responsive={responsive} />
          {overlay || null}

          {asset?.attachmentCount > 1 && (
            <PillBadge
              badgeType={BadgeType.Primary}
              badgeSize={BadgeSize.Small}
              style={{
                position: 'absolute',
                top: '4px',
                right: '4px',
                zIndex: 50,
                backgroundColor: stylingOptions.backgroundColorSecondary,
              }}
            >
              <span className="attachment-text-md attachment-adjust-margin">
                {index + 1}/{asset.attachmentCount}
              </span>
            </PillBadge>
          )}
        </div>
      </Thumbnail>

      {attachment && (
        <ThumbnailLabelContainer>
          <span className="flex flex-row items-center justify-between">
            <div className="flex flex-row truncate p-xxs">
              <Tooltip {...tooltipProps}>
                <TooltipBody>{useAssetName ? asset.name : attachment.name}</TooltipBody>
              </Tooltip>
              {/* This span is added here to serve as a target for the Tooltip. Forwarding the a reference to the exact label was attempted, but complicated as a result of forwarding it through nested components */}
              <span className="truncate" {...targetProps}>
                {selectionType === SelectionType.Multi && (
                  <BFCheckbox
                    data-testid="select-attachment-checkbox"
                    className="attachment-checkbox sq-20"
                    checked={selectedAttachmentIds.includes(attachment?.id)}
                    onChange={onSelection}
                    labelClassName="attachment-name font-medium truncate"
                    label={useAssetName ? asset.name : attachment.name}
                    disabled={
                      !isSupported(attachment?.mediaType) || asset.availability !== 'published'
                    }
                  />
                )}
                {selectionType === SelectionType.Single && (
                  <p>{useAssetName ? asset.name : attachment.name}</p>
                )}
              </span>
            </div>
            <AttachmentPlacementDetailsContainer
              attachmentID={attachment.id}
              assetID={asset.id}
              icon={true}
            />
            <PillBadge
              badgeType={BadgeType.Secondary}
              badgeSize={BadgeSize.Medium}
              style={{ backgroundColor: stylingOptions.backgroundColorSecondary }}
            >
              {attachment.extension?.toLocaleUpperCase()}
            </PillBadge>
          </span>
        </ThumbnailLabelContainer>
      )}
    </ThumbnailContainer>
  );
}
