import { Popover } from '@headlessui/react';
import {
  assetEntitySelectors,
  containerSelectors,
  IUploadAssetsOptions,
  uploadAssetsInit,
  UPLOAD_ASSETS_OPTIONS_TOKEN,
} from '@integration-frontends/integration/core/application';
import { logoutThunk } from '@integration-frontends/common/auth/core/application';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  IconHamburgerMenu,
  IconHome,
  IconLogout,
  IconOpenInBrandfolder,
  IconUploadAssets,
} from '@integration-frontends/common/ui';
import { usePopper } from 'react-popper';
import { Link } from 'react-router-dom';
import { DI_CONTAINER } from '@integration-frontends/core';
import {
  BRANDFOLDER_WEBSITE_SERVICE_TOKEN,
  IBrandfolderWebsiteService,
} from '@integration-frontends/integration/core/model';
import { useTranslation } from 'react-i18next';
import {
  HOME_PAGE_LABEL_KEY,
  INTEGRATION_COMMON_NAMESPACE,
  LOG_OUT_LABEL_KEY,
  OPEN_IN_BRANDFOLDER_LABEL_KEY,
  UPLOAD_ASSETS_LABEL_KEY,
} from '../common/i18n';
import { AnyAction } from '@reduxjs/toolkit';

interface PanelProps {
  assetId: string;
  containerId: string;
}

function Panel({ assetId, containerId }: PanelProps) {
  const bfWebsiteService: IBrandfolderWebsiteService = DI_CONTAINER.get(
    BRANDFOLDER_WEBSITE_SERVICE_TOKEN,
  );
  const { showUploadAssetsButton }: IUploadAssetsOptions = DI_CONTAINER.get(
    UPLOAD_ASSETS_OPTIONS_TOKEN,
  );
  const dispatch = useDispatch();
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const selectedContainer = useSelector((state) =>
    containerSelectors.selectById(state, containerId),
  );
  const selectedAsset = useSelector((state) => assetEntitySelectors.selectById(state, assetId));

  const onLogout = () => {
    dispatch(logoutThunk() as any);
  };

  const uploadInit = () => {
    dispatch(uploadAssetsInit);
  };

  return (
    <div className="menu-card-popover">
      <Link to="/select-attachment" className="text-base text-primary menu-card-item" data-cy="home-button">
        <IconHome className="mr-sm" />
        {t(HOME_PAGE_LABEL_KEY)}
      </Link>
      {(selectedAsset || selectedContainer) && (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={
            selectedAsset
              ? bfWebsiteService.getAssetPageUrl(selectedContainer, selectedAsset)
              : bfWebsiteService.getContainerUrl(selectedContainer)
          }
          className="text-base text-primary menu-card-item"
        >
          <IconOpenInBrandfolder className="mr-sm text-primary" />{' '}
          {t(OPEN_IN_BRANDFOLDER_LABEL_KEY)}
        </a>
      )}
      {showUploadAssetsButton && (
        <Link data-testid="upload-menu-item" to="/upload" className="text-base text-primary menu-card-item" onClick={uploadInit}>
          <IconUploadAssets className="mr-sm" /> {t(UPLOAD_ASSETS_LABEL_KEY)}
        </Link>
      )}
      <button
        data-cy="logout"
        className="text-base text-primary menu-card-item"
        onClick={onLogout}
      >
        <IconLogout className="mr-sm" /> {t(LOG_OUT_LABEL_KEY)}
      </button>
    </div>
  );
}

export interface MenuPopoutProps {
  assetId: string;
  containerId: string;
}

export function MenuPopout({ assetId, containerId }: MenuPopoutProps) {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
    modifiers: [{ name: 'offset', options: { offset: [0, 0] } }],
    strategy: 'absolute',
  });

  return (
    <Popover data-cy="popover-menu" data-testid="popover-menu" style={{ zIndex: 1000 }}>
      <Popover.Button as="button" ref={setReferenceElement}>
        <span className="text-base flex">
          <IconHamburgerMenu className="mr-xxs" /> Menu
        </span>
      </Popover.Button>
      <Popover.Panel ref={setPopperElement} style={styles.popper} {...attributes.popper}>
        <Panel assetId={assetId} containerId={containerId} />
      </Popover.Panel>
    </Popover>
  );
}
