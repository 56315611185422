import { useSearchParams } from 'react-router-dom';
import { SearchParameters } from '@integration-frontends/integration/core/model';
import {
  clearSearch,
  ISelectAttachmentOptions,
  SELECT_ATTACHMENT_OPTIONS_TOKEN,
  showPageSearch,
} from '@integration-frontends/integration/core/application';
import { DI_CONTAINER } from '@integration-frontends/core';
import { useDispatch } from 'react-redux';
import {
  DEFAULT_SORT_OPTIONS,
  SortOptions,
} from '@integration-frontends/integration/core/model/entities/common/sorting';

export function useSearch() {
  const dispatch = useDispatch();
  const [searchParamsFromRouter, setSearchParamsFromRouter] = useSearchParams();

  const { customSortOptions }: ISelectAttachmentOptions = DI_CONTAINER.get(
    SELECT_ATTACHMENT_OPTIONS_TOKEN,
  );

  function getSearchParams(): SearchParameters {
    const searchParams = searchParamsFromRouter.get('searchParams');
    return searchParams ? JSON.parse(searchParams) : {};
  }

  function getSortOptions(): SortOptions {
    const options = searchParamsFromRouter.get('sortOptions');
    return options ? JSON.parse(options) : customSortOptions || DEFAULT_SORT_OPTIONS;
  }

  function search(searchParams?: SearchParameters, sortOptions?: SortOptions) {
    searchParams = searchParams || getSearchParams();
    sortOptions = sortOptions || getSortOptions();

    dispatch(showPageSearch({ searchParams, sortOptions }));
    setSearchParamsFromRouter(
      new URLSearchParams({
        searchParams: JSON.stringify(searchParams),
        sortOptions: JSON.stringify(sortOptions),
      }),
    );
  }

  function clear() {
    dispatch(clearSearch({ executeSearch: true }));
    setSearchParamsFromRouter(new URLSearchParams({}));
  }

  return {
    searchParams: getSearchParams(),
    sortOptions: getSortOptions(),
    search,
    clear,
  };
}
