import {
  BFAnchor,
  BFButton,
  BFHR,
  BFPanel,
  ButtonSize,
  IconClose,
} from '@integration-frontends/common/ui';
import { useDependencyInjector } from '@integration-frontends/core/di-container/react';
import {
  Container,
  ContainerCustomField,
  ContainerFileTypeAggregates,
  ContainerTag,
  DEFAULT_SORT_OPTIONS,
  SearchParameters,
  SortOptions,
} from '@integration-frontends/integration/core/model';
import classNames from 'classnames';
import { noop } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { INTEGRATION_COMMON_NAMESPACE } from '../../../common/i18n';
import {
  INTEGRATION_UI_STYLING_OPTIONS_TOKEN,
  IntegrationUiStylingOptions,
} from '../../../styling-options';
import { APPLY_KEY, CLEAR_KEY, FILTER_RESULTS_KEY } from '../../i18n';
import './advanced-filters-component.scss';
import {
  AssetStatusFilter,
  CustomFieldFiltersContainer,
  OrientationFiltersContainer,
  PrioritizedCustomFieldsFiltersContainer,
  SkuFilterContainer,
  TopFileTypesFiltersContainer,
  TopTagsFiltersContainer,
  UploadDateFilterContainer,
} from './components';

export interface AdvancedFiltersProps {
  container: Container;
  containerCustomFields: ContainerCustomField[];
  searchParams: SearchParameters;
  searchParamsChanged: (params: SearchParameters) => void;
  sortOptions: SortOptions;
  onClose: () => void;
  onApply: (searchParams: SearchParameters, sortOptions: SortOptions) => void;
  onClear: () => void;
  responsive?: boolean;
  showAdvancedFiltersView?: boolean;
  tags: ContainerTag[];
  fileTypeAggregates: ContainerFileTypeAggregates[];
  dirty: boolean;
}

export const ADVANCED_FILTERS_COMPONENT_KEY = 'ADVANCED_FILTERS';

export function AdvancedFiltersComponent({
  container,
  containerCustomFields,
  searchParams = {},
  searchParamsChanged = noop,
  sortOptions = DEFAULT_SORT_OPTIONS,
  onClose = noop,
  onApply = noop,
  onClear = noop,
  responsive,
  showAdvancedFiltersView,
  tags,
  fileTypeAggregates,
  dirty,
}: AdvancedFiltersProps) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const showPageNavElement = document.getElementById('showPageNav');
  const showPageNavHeight = showPageNavElement ? showPageNavElement.offsetHeight : 0;
  const stylingOptions = useDependencyInjector<IntegrationUiStylingOptions>(
    INTEGRATION_UI_STYLING_OPTIONS_TOKEN,
  );

  const bodyElement = (
    <>
      <div
        className="flex justify-between items-center advanced-filters-header px-xl"
        style={{ flex: '0 0 60px', backgroundColor: stylingOptions?.backgroundColor }}
      >
        <IconClose className="cursor-pointer" onClick={onClose} />
        <span className="font-bold">{t(FILTER_RESULTS_KEY)}</span>
        <BFAnchor onClick={onClear}>{t(CLEAR_KEY)}</BFAnchor>
      </div>

      <BFHR />

      {containerCustomFields.length > 0 && (
        <>
          <PrioritizedCustomFieldsFiltersContainer
            customFields={containerCustomFields}
            searchParams={searchParams}
            onChange={searchParamsChanged}
          />
          <BFHR />
        </>
      )}

      <AssetStatusFilter searchParams={searchParams} onChange={searchParamsChanged} />

      <BFHR />

      <TopTagsFiltersContainer
        searchParams={searchParams}
        tags={tags}
        onChange={searchParamsChanged}
      />

      <BFHR />

      <TopFileTypesFiltersContainer
        fileTypeAggregates={fileTypeAggregates}
        searchParams={searchParams}
        onChange={searchParamsChanged}
      />

      <BFHR />

      <CustomFieldFiltersContainer
        containerId={container.id}
        searchParams={searchParams}
        onChange={searchParamsChanged}
      />

      <BFHR />

      <OrientationFiltersContainer searchParams={searchParams} onChange={searchParamsChanged} />

      <BFHR />

      <SkuFilterContainer searchParams={searchParams} onChange={searchParamsChanged} />

      <BFHR />

      <UploadDateFilterContainer searchParams={searchParams} onChange={searchParamsChanged} />

      {dirty && (
        <div className="z-10 bg-bf-white opacity-95 sticky bottom-0 rounded-b-md">
          <BFHR />
          <div className="flex justify-center items-center">
            <BFButton
              data-testid="apply-btn"
              className="my-md"
              size={ButtonSize.Small}
              style={{ width: 168 }}
              onClick={() => onApply(searchParams, sortOptions)}
            >
              {t(APPLY_KEY)}
            </BFButton>
          </div>
        </div>
      )}
    </>
  );

  return (
    <div
      id="advanced-filters"
      data-testid="advanced-filters"
      className={classNames('w-full h-full', {
        'advanced-filters-overlay-responsive': responsive,
        'advanced-filters-overlay': !responsive,
        open: showAdvancedFiltersView,
      })}
      style={{ backgroundColor: stylingOptions?.backgroundColor }}
    >
      {responsive ? (
        bodyElement
      ) : (
        <div className="w-full h-full overflow-y-auto">
          <BFPanel
            className="bg-bf-white rounded-md mx-auto left-0 right-0 my-md flex flex-col"
            style={{ width: 360, top: 20 }}
          >
            {bodyElement}
          </BFPanel>
        </div>
      )}
    </div>
  );
}
