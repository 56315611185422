import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { IconDragAndDrop } from '../icons/icon-drag-and-drop';
import './drop-zone.scss';

import { COMMON_DROP_ZONE_NAMESPACE, DRAG_FILES_KEY } from './i18n';

export interface BFDropZoneProps {
  className?: string;
  onDrop: (acceptedFiles: File[]) => void;
  style?: HTMLAttributes<any>['style'];
}

export function BFDropZone({ className, onDrop, style }: BFDropZoneProps) {
  const { t } = useTranslation(COMMON_DROP_ZONE_NAMESPACE);
  const { getRootProps, getInputProps } = useDropzone({ onDrop: onDrop });

  return (
    <div
      className={classNames(
        'drop-zone', 
        className,
      )}
      {...getRootProps()}
      style={{ minHeight: 100, ...style }}
    >
      <input {...getInputProps()} />
      <IconDragAndDrop />
      <span className="drop-zone-text">{t(DRAG_FILES_KEY)}</span>
    </div>
  );
}
