import classNames from 'classnames';
import { noop } from 'lodash';
import React, {
  DetailedHTMLProps,
  ImgHTMLAttributes,
  useCallback,
  useEffect,
  useState,
} from 'react';
import './styles/image.scss';

export interface BFImageProps {
  fallbackUrl?: string;
  responsive?: boolean;
  fullSizeUrl?: string;
  thumbnailUrl?: string;
  imageData?: any;
}

export function BFImage({
  fallbackUrl,
  fullSizeUrl,
  thumbnailUrl,
  src,
  onError = noop,
  className,
  responsive,
  imageData,
  ...props
}: BFImageProps & DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) {
  const [errored, setErrored] = useState(false);
  const [localSrc, setLocalSrc] = useState(src);
  const [mouseIn, setMouseIn] = useState(false);

  useEffect(() => {
    setErrored(false);
    setLocalSrc(src);
  }, [src]);

  const localOnError = useCallback((e) => {
    if (!errored) {
      setErrored(true);
      setLocalSrc(fallbackUrl);
    }

    onError(e);
  }, []);

  const noDrag = (ev) => {
    ev.preventDefault();
  };

  const handleDragStart = (ev) => {
    const img = document.createElement('img');
    ev.dataTransfer.setData('imageData', JSON.stringify(imageData || {}));
    img.src = thumbnailUrl || localSrc;
    ev.dataTransfer.setDragImage(img, 0, 0);
  };

  return (
    <>
      <img
        className={classNames(
          { 'image-responsive': responsive, 'object-contain': !responsive },
          responsive ? {} : className,
        )}
        data-cy="bf-image"
        src={localSrc}
        onError={localOnError}
        onDragStart={noDrag}
        onMouseEnter={() => setMouseIn(true)}
        {...props}
      />
      {mouseIn && (
        <img
          className="full-size-image-overlay"
          onDragStart={handleDragStart}
          data-cy="bf-image-full-overlay"
          src={fullSizeUrl}
          onError={localOnError}
          draggable={props.draggable}
        />
      )}
    </>
  );
}
