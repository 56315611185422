import React, { HTMLAttributes } from 'react';
import { IconArrowCaretLeft } from '@integration-frontends/common/ui';
import './back-button.scss';

export interface BackButtonProps {
  text: string;
}

export function BackButton({ text, ...props }: BackButtonProps & HTMLAttributes<any>) {
  return (
    <div className="back-button" {...props}>
      <IconArrowCaretLeft className="back-link-arrow" />
      <span className="button-text font-semi-bold truncate cta-link">{text}</span>
    </div>
  );
}
