import { put, takeEvery } from "redux-saga/effects";
import { searchRefresh } from "../../../search-assets";
import { uploadFailure } from "../actions";
import { createNotification, NotificationType } from '@integration-frontends/common/notifications';

const handler = function* (action: ReturnType<typeof uploadFailure>) {
  const { error } = action.payload;
  yield put(createNotification({message: error, location: 'uploadForm', type: NotificationType.Error}));
}

export function* uploadFailureEffects() {
  yield takeEvery(uploadFailure, handler)
}
