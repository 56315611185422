import {
  Collection,
  CollectionCustomField,
  CollectionFileTypeAggregates,
  collectionsMockData,
  CollectionTag,
  containerCustomFieldsMockData,
  containerFileTypeAggregatesMockData,
  containerTagsMockData,
  CustomFieldValueOption,
  customFieldValueOptionMockData,
  ICollectionRepo,
} from '@integration-frontends/integration/core/model';

export class CollectionRepoMock implements ICollectionRepo {
  getCollection(collectionId: string): Promise<Collection> {
    return Promise.resolve(collectionsMockData[0]);
  }

  getCollectionCustomFields(collectionId: string): Promise<CollectionCustomField[]> {
    return Promise.resolve(
      containerCustomFieldsMockData.map((customField) => ({ ...customField, collectionId })),
    );
  }

  getCollectionCustomFieldValues(collectionId: string): Promise<CustomFieldValueOption[]> {
    return Promise.resolve(customFieldValueOptionMockData);
  }

  getCollectionFileTypeAggregates(collectionId: string): Promise<CollectionFileTypeAggregates[]> {
    return Promise.resolve(
      containerFileTypeAggregatesMockData.map((fileTypeAggregate) => ({
        ...fileTypeAggregate,
        collectionId,
      })),
    );
  }

  getCollectionTags(collectionId: string): Promise<CollectionTag[]> {
    return Promise.resolve(containerTagsMockData.map((tag) => ({ ...tag, collectionId })));
  }

  listCollections(): Promise<Collection[]> {
    return Promise.resolve(collectionsMockData);
  }
}
