export enum AttachmentSelectorFeature {
  CdnEmbed,
  PlacementOptions,
  Responsive,
  DragAndDrop
}

export const ATTACHMENT_SELECTOR_FEATURES_TOKEN = 'ATTACHMENT_SELECTOR_FEATURES';

export interface IAttachmentSelectorFeaturesService {
  hasFeature: (feature: AttachmentSelectorFeature) => boolean;
}
