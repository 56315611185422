import {
  AlignmentOptions,
  CropOptions,
  LabelOptions,
  LinkOptions, ResizingOptions
} from "@integration-frontends/integration/core/application";

export interface AttachmentEditOptions {
  alignmentOptions?: AlignmentOptions;
  cropOptions?: CropOptions;
  labelOptions?: LabelOptions;
  linkOptions?: LinkOptions;
  resizingOptions?: ResizingOptions;
}
