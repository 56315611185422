/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import {
  AuthError,
  Credentials,
  IAuthenticateService,
  Identity,
} from '@integration-frontends/common/auth/core/model';

export const IDENTITY_RESPONSE_MOCK = {
  token: 'TEST',
  userId: 'TEST_ID',
  firstName: 'TEST_FIRST_NAME',
  lastName: 'TEST_LAST_NAME',
  email: 'email@test.com',
}

export class AuthenticationServiceMock implements IAuthenticateService {
  authenticate(credentials: Credentials): Promise<Identity | AuthError> {
    return Promise.resolve(IDENTITY_RESPONSE_MOCK);
  }

  isAuthenticated(token: string): Promise<boolean> {
    return Promise.resolve(false);
  }
}
