import {
  IntegrationEntitiesSelectors,
  SearchAssetsSelectors,
} from '@integration-frontends/integration/core/application';
import { all } from 'redux-saga/effects';
import { clearSearchEffects } from './clear-search';
import { initEffects } from './init';
import { paginationSectionEffects } from './pagination-by-section';
import { paginationContainerEffects } from './pagination-by-container';
import { searchEffects } from './search';
import { call } from 'typed-redux-saga';
import { filtersEffects } from '@integration-frontends/integration/core/application/search-assets/state/effects/filters';

export function* searchAssetsEffects(
  searchAssetsSelectors: SearchAssetsSelectors,
  entitiesSelectors: IntegrationEntitiesSelectors,
) {
  yield all([
    call(clearSearchEffects),
    call(initEffects),
    call(paginationContainerEffects, searchAssetsSelectors),
    call(paginationSectionEffects, searchAssetsSelectors),
    call(searchEffects, searchAssetsSelectors, entitiesSelectors),
    call(filtersEffects, searchAssetsSelectors),
  ]);
}
