import { DI_CONTAINER, ILogger, LOGGER_TOKEN } from '@integration-frontends/core';
import {
  collectionEntityActions,
  containerCustomFieldActions,
  containerFileTypeAggregateActions,
  containerTagsActions,
  labelEntityActions,
  searchFilterEntityActions,
  sectionEntityActions,
} from '@integration-frontends/integration/core/application/common';
import {
  Container,
  CONTAINER_REPO_TOKEN,
  IContainerRepo,
  ILabelRepo,
  ISearchFilterRepo,
  ISectionRepo,
  LABEL_REPO_TOKEN,
  ResourceType,
  SEARCH_FILTER_REPO_TOKEN,
  SECTION_REPO_TOKEN,
} from '@integration-frontends/integration/core/model';
import { prop } from 'ramda';
import { all, call, put, takeEvery } from 'typed-redux-saga';
import { searchAssetsActions } from '../actions';

function* handler(action: ReturnType<typeof searchAssetsActions.init>) {
  const { container } = action.payload;

  yield all([initSearch(container), initFilters(container)]);
}

function* initSearch(container: Container) {
  const sectionRepo: ISectionRepo = DI_CONTAINER.get(SECTION_REPO_TOKEN);

  const sections = yield call(sectionRepo.listContainerSections, container);

  if (container.type === ResourceType.COLLECTION) {
    yield put(
      collectionEntityActions.collectionUpdated({
        ...container,
        sectionIds: sections.map(prop('id')),
      }),
    );
  }

  yield put(sectionEntityActions.sectionsReceived(sections));
  yield put(searchAssetsActions.sectionsLoaded({ sectionIds: sections.map(prop('id')) }));
  yield put(searchAssetsActions.initSuccess());
}

function* initFilters(container: Container) {
  const containerRepo: IContainerRepo = DI_CONTAINER.get(CONTAINER_REPO_TOKEN);
  const labelRepo: ILabelRepo = DI_CONTAINER.get(LABEL_REPO_TOKEN);
  const searchFilterRepo: ISearchFilterRepo = DI_CONTAINER.get(SEARCH_FILTER_REPO_TOKEN);

  const [customFields, fileTypeAggregates, tags, labels, searchFilters] = yield all([
    call(tryPromiseOrDefault(containerRepo.getContainerCustomFields, []), container),
    call(tryPromiseOrDefault(containerRepo.getContainerFileTypeAggregates, []), container),
    call(tryPromiseOrDefault(containerRepo.getContainerTags, []), container),
    call(tryPromiseOrDefault(labelRepo.getContainerLabels, []), container),
    call(tryPromiseOrDefault(searchFilterRepo.listContainerSearchFilters, []), container),
  ]);

  yield put(containerCustomFieldActions.containerCustomFieldsReceived(customFields));
  yield put(
    containerFileTypeAggregateActions.containerFileTypeAggregatesReceived(fileTypeAggregates),
  );
  yield put(containerTagsActions.containerTagsReceived(tags));
  yield put(labelEntityActions.labelsReceived(labels));
  yield put(searchFilterEntityActions.searchFiltersReceived(searchFilters));
  yield put(searchAssetsActions.initSearchFiltersSuccess());
}

function tryPromiseOrDefault(fn, defaultValue: any) {
  const logger: ILogger = DI_CONTAINER.get(LOGGER_TOKEN);

  return async function () {
    try {
      return await fn.apply(null, arguments);
    } catch (e) {
      logger.error(e);
      return defaultValue;
    }
  };
}

export function* initEffects() {
  yield takeEvery(searchAssetsActions.init, function* (action) {
    yield handler(action);
  });
}
