import { Popover } from '@headlessui/react';
import { BFIconButton, IconEllipsis } from '@integration-frontends/common/ui';
import { PopoverMenu, PopoverMenuItem } from '@integration-frontends/common/ui/menu/popover-menu';
import { DI_CONTAINER } from '@integration-frontends/core';
import { containerSelectors } from '@integration-frontends/integration/core/application';
import {
  Asset,
  BRANDFOLDER_WEBSITE_SERVICE_TOKEN,
  IBrandfolderWebsiteService,
} from '@integration-frontends/integration/core/model';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePopper } from 'react-popper';
import { useSelector } from 'react-redux';
import { INTEGRATION_COMMON_NAMESPACE } from '../../../../common/i18n';
import { OPEN_IN_BRANDFOLDER_KEY, VIEW_ASSET_DETAILS_KEY } from '../../../i18n';
import { useAttachmentSelectorNavigation } from '../../../navigation';

export interface AssetThumbnailMenuProps {
  asset: Asset;
  containerId: string;
  onFocus?: () => void;
  onBlur?: () => void;
}

export function AssetThumbnailMenu({
  asset,
  containerId,
  onFocus,
  onBlur,
}: AssetThumbnailMenuProps) {
  const bfWebsiteService: IBrandfolderWebsiteService = DI_CONTAINER.get(
    BRANDFOLDER_WEBSITE_SERVICE_TOKEN,
  );
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const selectedContainer = useSelector((state) =>
    containerSelectors.selectById(state, containerId),
  );
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [{ name: 'offset', options: { offset: [0, 5] } }],
  });
  const navigation = useAttachmentSelectorNavigation();

  return (
    <div className="w-full flex justify-end">
      <Popover>
        <Popover.Button onFocus={onFocus} onBlur={onBlur} as="div" ref={setReferenceElement}>
          <BFIconButton
            data-testid="more-options-btn"
            className="bg-bf-white"
            style={{ width: 30, height: 30 }}
            iconElement={<IconEllipsis />}
            ariaLabel="more actions"
          />
        </Popover.Button>

        <Popover.Panel
          ref={setPopperElement}
          className="z-100"
          style={styles.popper}
          {...attributes.popper}
        >
          <PopoverMenu>
            <PopoverMenuItem
              data-testid="view-details-action"
              onClick={() => navigation.goToAssetDetails(asset.id, selectedContainer.id)}
              onFocus={onFocus}
              onBlur={onBlur}
            >
              {t(VIEW_ASSET_DETAILS_KEY)}
            </PopoverMenuItem>

            <PopoverMenuItem onFocus={onFocus} onBlur={onBlur}>
              <Popover.Button>
                <a
                  target="_blank"
                  href={bfWebsiteService.getAssetPageUrl(selectedContainer, asset)}
                  onFocus={onFocus}
                  onBlur={onBlur}
                >
                  {t(OPEN_IN_BRANDFOLDER_KEY)}
                </a>
              </Popover.Button>
            </PopoverMenuItem>
          </PopoverMenu>
        </Popover.Panel>
      </Popover>
    </div>
  );
}
