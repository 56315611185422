import {
  Container,
  IUserSettingsRepo,
  ResourceType,
  UploadPreferencesResults,
} from '@integration-frontends/integration/core/model';
import { injectable } from 'inversify';
import { RepoBase } from './repo-base';

@injectable()
export class UserSettingsRepo extends RepoBase implements IUserSettingsRepo {
  setAssetUploadPreference = async (
    organizationId: string,
    container: Container,
    sectionId: string,
  ): Promise<UploadPreferencesResults> => {
    const brandfolderId: string =
      container.type === ResourceType.BRANDFOLDER ? container.id : container.brandfolderId;
    const collectionId: string | null =
      container.type === ResourceType.COLLECTION ? container.id : null;
    return this.brandfolderApi
      .saveUploadPreferences(
        await this.getApiKey(),
        organizationId,
        brandfolderId,
        sectionId,
        collectionId,
      )
      .then((res) => this._mapUploadPreferences(res));
  };

  getAssetUploadPreference = async (): Promise<UploadPreferencesResults> => {
    return this.brandfolderApi
      .getUploadPreferences(await this.getApiKey())
      .then((res) => this._mapUploadPreferences(res));
  };

  private _mapUploadPreferences(res: any): UploadPreferencesResults {
    return {
      organizationId: res?.data?.attributes?.organization_key || '',
      brandfolderId: res?.data?.attributes?.brandfolder_key || '',
      collectionId: res?.data?.attributes?.collection_key || '',
      sectionId: res?.data?.attributes?.section_key || '',
      success: !!res?.data?.attributes?.organization_key,
    };
  }
}
