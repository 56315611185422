import { withFormControlStyles } from '@integration-frontends/common/ui/forms/form-controls/common';
import React, { HTMLAttributes, SelectHTMLAttributes, useCallback } from 'react';
import {
  COMPONENT_RESOLVER_TOKEN,
  ComponentResolver,
  DI_CONTAINER,
} from '@integration-frontends/core';
import classNames from 'classnames';

export const SELECT_COMPONENT_KEY = 'SELECT';

export enum BFSelectSize {
  Small = 's',
  Medium = 'm',
}

export interface BFSelectOption {
  label: string | number;
  value: string | number;
}

export type BFSelectProps = {
  options: BFSelectOption[];
  onOptionChange?: (option: BFSelectOption) => void;
  isClearable?: boolean;
  size?: BFSelectSize;
  onMenuOpen?: () => void;
} & Omit<HTMLAttributes<HTMLElement>, 'onChange'> &
  Omit<SelectHTMLAttributes<HTMLElement>, 'onChange' | 'size'>;

function DefaultSelect({
  className,
  isClearable,
  onOptionChange,
  options,
  placeholder,
  value,
  size = BFSelectSize.Small,
  ...restProps
}: BFSelectProps) {
  const internalOnChange = useCallback(
    (e) => {
      const value = e.target.value;
      const selectedOption = options.find((opt) => opt.value.toString() === value.toString());
      onOptionChange && onOptionChange(selectedOption);
    },
    [onOptionChange, options],
  );

  return (
    <select
      className={classNames('bf-select', size, className)}
      value={value || ''}
      {...restProps}
      onChange={internalOnChange}
    >
      {placeholder && <option value="">{placeholder}</option>}
      {options.map((option) => (
        <option data-testid={option.value} key={option.label} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
}

export const BFSelect = withFormControlStyles<BFSelectProps>((props) => {
  const componentResolver: ComponentResolver = DI_CONTAINER.get(COMPONENT_RESOLVER_TOKEN);
  const Component = componentResolver.getComponent(SELECT_COMPONENT_KEY) || DefaultSelect;
  return <Component {...props} />;
});
