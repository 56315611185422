import {
  AttachmentFieldParameter,
  FieldParameter,
  getResponseDataOrDefault,
  getResponseListDataOrDefault,
  Relationship,
} from '@integration-frontends/common/brandfolder-api';
import { IAttachmentRepo } from '@integration-frontends/integration/core/model';
import { injectable } from 'inversify';
import { map } from 'ramda';
import { AttachmentWithDto, mapAttachmentDto } from './model';
import { RepoBase } from './repo-base';

@injectable()
export class AttachmentRepo extends RepoBase implements IAttachmentRepo {
  constructor(private extraParameters: AttachmentFieldParameter[] = []) {
    super();
  }

  getAttachment = async (attachmentId: string): Promise<AttachmentWithDto> => {
    return this.brandfolderApi
      .fetchAttachment(await this.getApiKey(), attachmentId, {
        include: [Relationship.ASSET],
        fields: this.getFieldParameters(),
      })
      .then(getResponseDataOrDefault)
      .then(mapAttachmentDto);
  };

  listAssetAttachments = async (assetId: string): Promise<AttachmentWithDto[]> => {
    return this.brandfolderApi
      .listAssetAttachments(await this.getApiKey(), assetId, {
        include: [Relationship.ASSET],
        fields: this.getFieldParameters(),
      })
      .then(getResponseListDataOrDefault)
      .then(map(mapAttachmentDto));
  };

  private getFieldParameters(): AttachmentFieldParameter[] {
    return [
      FieldParameter.CdnUrl,
      FieldParameter.CreatedAt,
      FieldParameter.UpdatedAt,
      ...this.extraParameters,
    ];
  }
}
