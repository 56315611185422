import { Asset } from '@integration-frontends/integration/core/model';
import { CustomFieldKeyValueDto } from '@integration-frontends/common/brandfolder-api';
import { createReducer } from '@reduxjs/toolkit';
import {
  addAssets,
  backToHome,
  removeAsset,
  selectCollection,
  selectContainer,
  selectOrganization,
  selectSection,
  upload,
  uploadAssetsInit,
  uploadAssetsInitSuccess,
  uploadFailure,
  uploadMore,
  uploadSuccess,
} from './actions';

export type AssetData = { name: string; url: string };

export interface UploadAssetsState {
  assets: AssetData[];
  loading: boolean;
  selectedContainerId: string;
  selectedOrganizationId: string;
  selectedSectionId: string;
  selectedCollectionId: string;
  customFieldsData?: CustomFieldKeyValueDto;
  tagsData?: string[];
  success: boolean;
  uploadedAsset: Asset;
  shareLink: string;
  uploading: boolean;
}

export const uploadAssetsInitialState: UploadAssetsState = {
  assets: [],
  loading: false,
  selectedContainerId: null,
  selectedOrganizationId: null,
  selectedSectionId: null,
  selectedCollectionId: null,
  customFieldsData: null,
  tagsData: null,
  success: false,
  uploadedAsset: null,
  shareLink: null,
  uploading: false,
};

export const UPLOAD_ASSETS_REDUCER = createReducer(uploadAssetsInitialState, (builder) =>
  builder
    .addCase(uploadAssetsInit, (state, action) => {
      state.customFieldsData = action.payload.customFields;
      state.tagsData = action.payload.tags;
      state.loading = true;
    })
    .addCase(uploadAssetsInitSuccess, (state) => {
      state.loading = false;
    })
    .addCase(selectOrganization, (state, action) => {
      state.selectedOrganizationId = action.payload.id;
      state.selectedContainerId = null;
      state.selectedSectionId = null;
    })
    .addCase(selectContainer, (state, action) => {
      state.selectedContainerId = action.payload.container.id;
      state.selectedSectionId = null;
    })
    .addCase(selectSection, (state, action) => {
      state.selectedSectionId = action.payload.id;
    })
    .addCase(selectCollection, (state, action) => {
      state.selectedCollectionId = action.payload.id;
    })
    .addCase(addAssets, (state, action) => {
      state.assets = action.payload.assets;
    })
    .addCase(removeAsset, (state, action) => {
      state.assets = state.assets.filter((img) => img.name !== action.payload.asset.name);
    })
    .addCase(upload, (state) => {
      state.uploading = true;
    })
    .addCase(uploadSuccess, (state, action) => {
      state.uploading = false;
      state.success = true;
      state.shareLink = action.payload.shareLink;
      state.uploadedAsset = action.payload.asset;
    })
    .addCase(uploadFailure, (state, action) => {
      state.uploading = false;
    })
    .addCase(uploadMore, () => {
      return uploadAssetsInitialState;
    })
    .addCase(backToHome, () => {
      return uploadAssetsInitialState;
    }),
);
