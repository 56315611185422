import {
  attachmentDragEnded,
  attachmentDragStarted,
} from '@integration-frontends/integration/core/application';
import { Attachment } from '@integration-frontends/integration/core/model';
import classNames from 'classnames';
import React, { DragEventHandler, HTMLAttributes } from 'react';
import { useDispatch } from 'react-redux';
import './attachment-drag-and-drop.scss';

export type AttachmentImageProps = { attachment: Attachment } & HTMLAttributes<HTMLElement>;

export function AttachmentDragAndDrop({ attachment, ...restProps }: AttachmentImageProps) {
  const [dragging, setDragging] = React.useState(false);
  const dispatch = useDispatch();

  const handleDragStart: DragEventHandler = async (ev) => {
    ev.dataTransfer.setData('imageData', JSON.stringify(attachment || {}));
    setDragging(true);
    dispatch(attachmentDragStarted({ attachment }));
  };

  const handleDragEnd: DragEventHandler = () => {
    setDragging(false);
    dispatch(attachmentDragEnded({ attachment }));
  };

  return (
    <div
      draggable
      className={classNames('attachment-drag-and-drop-container', {
        dragging,
      })}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      {...restProps}
    />
  );
}
