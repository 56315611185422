import { DI_CONTAINER } from '@integration-frontends/core';
import { useDependencyInjector } from '@integration-frontends/core/di-container/react';
import {
  Attachment,
  IMediaTypeSupportService,
  MEDIA_TYPE_SUPPORT_SERVICE_TOKEN,
} from '@integration-frontends/integration/core/model';
import {
  ATTACHMENT_SELECTOR_FEATURES_TOKEN,
  AttachmentSelectorFeature,
  IAttachmentSelectorFeaturesService,
} from '@integration-frontends/integration/ui';
import { Thumbnail } from '@integration-frontends/integration/ui/attachment-selector/common';
import { AttachmentDragAndDrop } from '@integration-frontends/integration/ui/common/attachment-drag-and-drop';
import { AttachmentThumbnailImage } from '@integration-frontends/integration/ui/common/attachment-thumbnail-image';
import React from 'react';

export interface AttachmentThumbnailProps {
  attachment: Attachment;
  selected: boolean;
}

export function AttachmentThumbnail({ attachment, selected }: AttachmentThumbnailProps) {
  const featureService: IAttachmentSelectorFeaturesService = useDependencyInjector(
    ATTACHMENT_SELECTOR_FEATURES_TOKEN,
  );

  const mediaTypeSupportService = DI_CONTAINER.get<IMediaTypeSupportService>(
    MEDIA_TYPE_SUPPORT_SERVICE_TOKEN,
  );

  const thumbnail = (
    <Thumbnail
      role="img"
      aria-label="Thumbnail image preview of asset"
      selected={selected}
      className="h-full w-full"
    >
      <AttachmentThumbnailImage
        draggable={false}
        alt="Thumbnail preview of attachment"
        attachment={attachment}
      />
    </Thumbnail>
  );

  const containerStyle = { flex: '0 0 100px', height: 80 };
  return featureService.hasFeature(AttachmentSelectorFeature.DragAndDrop) &&
    mediaTypeSupportService.isSupported(attachment?.mediaType) ? (
    <AttachmentDragAndDrop attachment={attachment} style={containerStyle}>
      {thumbnail}
    </AttachmentDragAndDrop>
  ) : (
    <div style={containerStyle}>{thumbnail}</div>
  );
}
