import { Popover } from '@headlessui/react';
import { sendSegmentAction } from '@integration-frontends/common/analytics';
import {
  BFIconButton,
  enterToClick,
  IconAdd,
  IconMoreOptions,
  IconOpenInBrandfolder,
} from '@integration-frontends/common/ui';
import { IconDownload } from '@integration-frontends/common/ui/icons/icon-download';
import { IconEye } from '@integration-frontends/common/ui/icons/icon-eye';
import { PopoverMenu, PopoverMenuItem } from '@integration-frontends/common/ui/menu/popover-menu';
import { DI_CONTAINER } from '@integration-frontends/core';
import {
  initiatePlaceAttachment,
  ISelectAttachmentOptions,
  searchAssetsActions,
  SELECT_ATTACHMENT_OPTIONS_TOKEN,
  SelectActionType,
} from '@integration-frontends/integration/core/application';
import {
  Asset,
  Attachment,
  BRANDFOLDER_WEBSITE_SERVICE_TOKEN,
  Container,
  DOWNLOAD_SERVICE_TOKEN,
  IBrandfolderWebsiteService,
  IDownloadService,
} from '@integration-frontends/integration/core/model';
import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePopper } from 'react-popper';
import { useDispatch } from 'react-redux';
import { INTEGRATION_COMMON_NAMESPACE, OPEN_IN_BRANDFOLDER_LABEL_KEY } from '../../common/i18n';
import { useMediaTypeSupport } from '../../common/use-media-type-support';
import { DOWNLOAD_KEY, PLACE_KEY, VIEW_DETAILS_KEY } from '../i18n';
import { useAttachmentSelectorNavigation } from '../navigation';
import { useAttachmentActions } from './attachment-actions/use-attachment-action';

interface AttachmentOptionsProps {
  asset: Asset;
  attachment: Attachment;
  container: Container;
  buttonTransparent?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
}

export function AttachmentOptionsMenu({
  asset,
  attachment,
  buttonTransparent,
  container,
  onBlur,
  onFocus,
}: AttachmentOptionsProps) {
  const { availability } = asset;
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const dispatch = useDispatch();
  const bfWebsiteService: IBrandfolderWebsiteService = DI_CONTAINER.get(
    BRANDFOLDER_WEBSITE_SERVICE_TOKEN,
  );
  const downloadService: IDownloadService = DI_CONTAINER.get(DOWNLOAD_SERVICE_TOKEN);
  const selectOptions: ISelectAttachmentOptions = DI_CONTAINER.get(SELECT_ATTACHMENT_OPTIONS_TOKEN);
  const selectActionType: SelectActionType = selectOptions.actionType;
  const [moreOptionsButtonElement, setMoreOptionsButtonElement] = useState(null);
  const [optionsPopupElement, setOptionsPopupElement] = useState(null);
  const { styles, attributes } = usePopper(moreOptionsButtonElement, optionsPopupElement, {
    placement: 'bottom-end',
    modifiers: [{ name: 'offset', options: { offset: [0, 10] } }],
    strategy: 'absolute',
  });
  const { attachmentActionContainers } = useAttachmentActions();
  const { allSupported } = useMediaTypeSupport();
  const navigation = useAttachmentSelectorNavigation();

  const downloadClicked = useCallback(() => {
    dispatch(searchAssetsActions.downloadAttachment());
    dispatch(sendSegmentAction({ event: 'download' }));
    window.open(downloadService.getAttachmentDownloadLink(container, attachment));
  }, [container, attachment]);

  const placeClicked = useCallback(() => {
    dispatch(sendSegmentAction({ event: 'placed' }));
    dispatch(initiatePlaceAttachment({ attachment }));
  }, [attachment]);

  const visitInBrandfolderClicked = useCallback(() => {
    dispatch(sendSegmentAction({ event: 'openInBrandfolder' }));
    window.open(bfWebsiteService.getAssetPageUrl(container, asset), '_blank');
  }, [asset, container]);

  return (
    <Popover>
      {({ open }) => (
        <>
          <Popover.Button as="div">
            <BFIconButton
              className={classNames(
                'attachment-options-button',
                { open: open },
                { 'transparent-background': buttonTransparent },
              )}
              data-testid="more-options-btn"
              iconElement={<IconMoreOptions />}
              ref={setMoreOptionsButtonElement}
              ariaLabel="more options"
              onBlur={onBlur}
              onFocus={onFocus}
            />
          </Popover.Button>

          <Popover.Panel
            data-testid="more-options-menu-panel-container"
            className="more-options-menu-panel-container"
            ref={setOptionsPopupElement}
            style={{ ...styles.popper }}
            {...attributes.popper}
          >
            {({ close }) => {
              return (
                <PopoverMenu>
                  {attachmentActionContainers.map((Container, idx) => (
                    <Container
                      key={idx}
                      selectedAttachments={[attachment]}
                      location="show-page"
                      render={({ id, enabled, Icon, label, onClick }) => {
                        return (
                          <PopoverMenuItem
                            disabled={!enabled || !allSupported([attachment?.mediaType])}
                            onClick={onClick}
                            data-testid={`attachment-action-${id}`}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            onKeyDown={enterToClick}
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            {Icon && <Icon />}
                            {label}
                          </PopoverMenuItem>
                        );
                      }}
                    />
                  ))}

                  {attachmentActionContainers.length === 0 &&
                    selectActionType === SelectActionType.Place && (
                      <PopoverMenuItem
                        data-testid="place-action"
                        onClick={() => {
                          placeClicked();
                          close();
                        }}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        onKeyDown={enterToClick}
                      >
                        {/* TODO: temporary until we get the designs */}
                        <IconAdd />
                        {t(PLACE_KEY)}
                      </PopoverMenuItem>
                    )}

                  <PopoverMenuItem
                    data-testid="view-details-action"
                    onClick={() =>
                      navigation.goToAssetDetails(asset.id, container.id, attachment.id)
                    }
                    onBlur={onBlur}
                    onFocus={onFocus}
                    onKeyDown={enterToClick}
                  >
                    <IconEye />
                    {t(VIEW_DETAILS_KEY)}
                  </PopoverMenuItem>

                  {container && (
                    <PopoverMenuItem
                      onClick={() => {
                        visitInBrandfolderClicked();
                        close();
                      }}
                      onBlur={onBlur}
                      onFocus={onFocus}
                      onKeyDown={enterToClick}
                    >
                      <IconOpenInBrandfolder />
                      {t(OPEN_IN_BRANDFOLDER_LABEL_KEY)}
                    </PopoverMenuItem>
                  )}

                  {container && (
                    <PopoverMenuItem
                      onClick={() => {
                        downloadClicked();
                        close();
                      }}
                      onBlur={onBlur}
                      onFocus={onFocus}
                      onKeyDown={enterToClick}
                      disabled={availability === 'view only'}
                    >
                      <IconDownload />
                      {t(DOWNLOAD_KEY)}
                    </PopoverMenuItem>
                  )}
                </PopoverMenu>
              );
            }}
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
}
