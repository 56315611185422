import { Disclosure } from '@headlessui/react';
import { BFIconButton, IconArrowRight, IconLogo } from '@integration-frontends/common/ui';
import {
  Brandfolder,
  Collection,
  Container,
  hasCardImage,
} from '@integration-frontends/integration/core/model';
import classNames from 'classnames';
import { noop } from 'lodash';
import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { CollectionsToggle, ContainersCompactList } from '../../common';
import { ASSET_KEY } from '../../i18n';
import { INTEGRATION_COMMON_NAMESPACE } from '@integration-frontends/integration/ui/common/i18n';
import './brandfolder-list-item.scss';
import { useAttachmentSelectorNavigation } from '@integration-frontends/integration/ui/attachment-selector/navigation';

export function BrandfolderListItem({
  brandfolder,
  brandfolderCount,
  collections,
  onClick,
  className,
  ...props
}: {
  brandfolder: Brandfolder;
  collections: Collection[];
  brandfolderCount: number;
} & HTMLAttributes<any>) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const navigation = useAttachmentSelectorNavigation();

  const ContainerInfo = ({
    container,
    ...props
  }: { container: Container } & HTMLAttributes<any>) => (
    <div className="flex flex-col overflow-hidden w-full" {...props}>
      <span className="truncate font-medium container-name">{container.name}</span>
      {container.hasAccess && (
        <span className="text-base container-asset-count">
          {container.assetCount.toLocaleString('en-US')}{' '}
          {t(ASSET_KEY, { count: container.assetCount })}
        </span>
      )}
    </div>
  );

  const BrandfolderImage = ({ brandfolder }: { brandfolder: Brandfolder }) => {
    return (
      <div className="container-thumbnail">
        {hasCardImage(brandfolder) ? (
          <img src={brandfolder.cardImage} alt={brandfolder.name} />
        ) : (
          <IconLogo />
        )}
      </div>
    );
  };

  const ArrowButton = () => {
    return (
      <BFIconButton
        data-testid="select-brandfolder-btn"
        style={{ width: 30, height: 30 }}
        iconElement={<IconArrowRight className="icon-arrow-right" style={{ width: 16 }} />}
        transparentBackground={true}
        ariaLabel="select brandfolder button"
      />
    );
  };

  function onCollectionSelected(collection: Collection) {
    navigation.goToShowPage(collection.id);
  }

  const CollectionsList = ({
    brandfolder,
    collections,
  }: {
    brandfolder: Brandfolder;
    collections: Collection[];
  }) => {
    return (
      <Disclosure defaultOpen={!brandfolder.hasAccess}>
        {({ open }) => (
          <div
            className="w-full"
            onKeyDown={(e) => e.stopPropagation()}
            onClick={(e) => e.stopPropagation()}
          >
            <Disclosure.Button
              data-cy="collections-toggle"
              className="flex items-center collections-toggle-button"
            >
              <CollectionsToggle collectionCount={collections.length} open={open} primary={false} />
            </Disclosure.Button>

            <Disclosure.Panel className="w-full">
              <ContainersCompactList
                containers={collections}
                containerSelect={onCollectionSelected}
                listItemDelimiter={false}
              />
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
    );
  };

  return (
    <li
      data-testid={brandfolder.type}
      data-cy={brandfolder.type}
      data-is-focusable={true}
      className={classNames('brandfolder-list-item', 'text-primary cursor-pointer', className)}
      onClick={brandfolder.hasAccess ? onClick : noop}
      {...props}
    >
      <BrandfolderImage brandfolder={brandfolder} />

      <div className="flex flex-col flex-grow items-start overflow-hidden">
        <ContainerInfo container={brandfolder} />
        {collections.length > 0 && (
          <CollectionsList brandfolder={brandfolder} collections={collections} />
        )}
      </div>

      {brandfolderCount > 0 && brandfolder.hasAccess && <ArrowButton />}
    </li>
  );
}
